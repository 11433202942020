import React, { useState, useEffect } from 'react';
import classes from './BankRecon.module.css';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
// import favicon from '../../Images/faviconn.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import CashDash from '../Cash Dash/CashDash';
import Select from 'react-select';
import PaymentDash from '../Payment Dashboard/PaymentDash';
import LatestDash from '../LatestDashboard/LatestDash';
import portal from "../../assets/nw/portal.svg";
import notification from "../../assets/nw/notification.svg";
import user from "../../assets/nw/manageUser.svg";
import modules from "../../assets/nw/modules.svg";
import currency from "../../assets/nw/currency.svg";
import down from "../../assets/nw/dropdown.svg";
import plus from "../../assets/nw/plus.svg";
import chart from "../../assets/nw/chart.svg"
import vector from "../../assets/nw/vector.svg";
import linear from "../../assets/nw/linear.svg";
import s from "../../assets/nw/delete.svg";
import downs from "../../assets/nw/down1.svg"
import { Tabs, Tab } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoChatbubblesSharp } from "react-icons/io5";
import { FaUpload } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { ImCancelCircle } from "react-icons/im";
import { FaRegSave } from "react-icons/fa";
import { GrPowerCycle } from "react-icons/gr";
import { FaPrint } from "react-icons/fa";
import MobileMenu from '../../Component/Mobile/MobileNav';




export default function BankReconciliation() {
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [unmatch, setUnmatch] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPages, setCurrentPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerms, setSearchTerms] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [user, setUser] = useState('');
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [periodd, setPeriodd] = useState([]);
  const [selectedPeriodd, setSelectedPeriodd] = useState(null);
  const [selectedBank, setSelectedBank] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableDatas, setTableDatas] = useState([]);
  const [newData, setNewData] = useState([]);
  const [currentPageDatas, setCurrentPageDatas] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [newDatas, setNewDatas] = useState([]);
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');
  const [userTypes, setUserTypes] = useState('');
  const [admin, setAdmin] = useState('');
  const [openNav, setOpenNav] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAlls, setSelectAlls] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemss, setSelectedItemss] = useState([]);
  const [totalCashbookBalance, setTotalCashbookBalance] = useState(0);
  const [totalStatementBalance, setTotalStatementBalance] = useState(0);
  const [matchedAmount, setMatchedAmount] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [matchedDisplayed, setMatchedDisplay] = useState(false);



  useEffect(() => {
    if (matchedAmount === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [matchedAmount]);


  const ITEMS_PER_PAGE = 100;

  const totalPages = Math.ceil(tableData.length / ITEMS_PER_PAGE);
  const totalPages1 = Math.ceil(tableDatas.length / ITEMS_PER_PAGE);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();

  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };




  const fetchTransaction = async () => {
    setLoad(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/get_unmatched_reconciliation`,
        {
          params: {
            account_id: selectedBank,
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      console.log(response);
      const results = response.data?.data?.statements;
      setTableDatas(results);
      const resultss = response.data?.data?.cashbooks;
      setTableData(resultss);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
      setTableDatas([]);
    } finally {
      setLoad(false);
    }
  };

  const fetchAllMatched = async () => {
    setLoad(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/get_matched_reconciliation`,
        {
          params: {
            account_id: selectedBank,
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      console.log(response);
      const results = response.data?.data?.statements;
      setTableDatas(results);
      const resultss = response.data?.data?.cashbooks;
      setTableData(resultss);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
      setTableDatas([]);
    } finally {
      setLoad(false);
    }
  };

  const fetchAllMatchedDate = async () => {
    setLoad(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/get_matched_reconciliation_with_date`,
        {
          params: {
            account_id: selectedBank,
            date: selectedPeriodd
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      console.log(response);
      const results = response.data?.data?.statements;
      setTableDatas(results);
      const resultss = response.data?.data?.cashbooks;
      setTableData(resultss);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
      setTableDatas([]);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedBank) {
      fetchTransaction(selectedBank);
    }
  }, [bearer && selectedBank]);


  useEffect(() => {
    if (selectedMatch === 1) {
      fetchAllMatched();
      setMatchedDisplay(true);
    } else {
      fetchTransaction(selectedBank);
      setMatchedDisplay(false);
    }
  }, [selectedMatch]);
  useEffect(() => {
    if (selectedMatch === 1) {
      fetchAllMatched();
      setMatchedDisplay(true);
    } else {
      fetchTransaction(selectedBank);
      setMatchedDisplay(false);
    }
  }, [selectedMatch]);

  useEffect(() => {
    if (selectedPeriodd && selectedBank) {
      fetchAllMatchedDate(selectedPeriodd && selectedBank);
      setMatchedDisplay(true);
    } else {
      fetchTransaction(selectedBank);
      setMatchedDisplay(false);
    }
  }, [selectedPeriodd, selectedBank]);
  



  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption.value);
    setMatchedDisplay(false);
    setSelectedPeriodd(null);
    setSelectedMatch(null);
    

  };
  const handlePeriodChangee = (selectedOption) => {
    setSelectedPeriodd(selectedOption.value);
    setMatchedDisplay(false);

  };
  const handleMatchChange = (selectedOption) => {
    setSelectedMatch(selectedOption.value);

  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const daySuffix = getDaySuffix(day);

    return `${day} ${month}, ${year}`;
  }

  function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th'; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  const fetchDebit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/cash-and-bank`, { headers });
      const debitAcct = response.data?.data;

      const options1 = debitAcct.map((item) => ({
        label: item.gl_name,
        value: item.id,
        acNo: item.gl_code,
      }));

      setTableData1(debitAcct);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchPerriod = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/get-province-matched-date`, { headers });
      const debitAcct = response.data?.data;

      const options12 = [
        { label: "Select Date", value: "" },
        ...debitAcct.map((item) => ({
          label: item,
          value: item,
        })),
      ];

      // setTableData1(debitAcct);
      setPeriodd(options12);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setPeriodd([]);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchDebit();
      fetchPerriod();
    }
  }, [bearer]);


  const filterTableData = () => {
    const normalizedSearchTerm = searchTerm.toLowerCase();
    const filteredData = tableData.filter(item =>
      item.details.toLowerCase().includes(normalizedSearchTerm) ||
      item.amount.toString().includes(normalizedSearchTerm)
    );

    // Paginate filtered data
    const paginatedData = filteredData.slice((currentPage - 1) * 100, currentPage * 100);
    setCurrentPageData(paginatedData);
  };

  // Function to filter and paginate data for tableDatas
  const filterTableDatas = () => {
    const normalizedSearchTerms = searchTerms.toLowerCase();
    const filteredDatas = tableDatas.filter(item =>
      item.description.toLowerCase().includes(normalizedSearchTerms) ||
      item.amount.toString().includes(normalizedSearchTerms)
    );

    // Paginate filtered data
    const paginatedDatas = filteredDatas.slice((currentPages - 1) * 100, currentPages * 100);
    setCurrentPageDatas(paginatedDatas);
  };

  useEffect(() => {
    filterTableData();
  }, [searchTerm, currentPage, tableData]);

  useEffect(() => {
    filterTableDatas();
  }, [searchTerms, currentPages, tableDatas]);

  // const currentPageDatas = filteredDatas.slice((currentPages - 1) * 100, currentPages * 100);
  // const currentPageData = filteredData.slice((currentPage - 1) * 100, currentPage * 100);

  const handleSave = async () => {
    setLoading(true);

    try {

      const selectedItemsData = await AsyncStorage.getItem('cashbook');
      const selectedItemsDatas = await AsyncStorage.getItem('statement');

      if (!selectedItemsData || !selectedItemsDatas) {

        Swal.fire({
          icon: 'warning',
          title: 'No Saved Data',
          text: 'There is no data saved. Please select items before proceeding.',
        });
        return;
      }


      const result = await Swal.fire({
        icon: 'question',
        title: 'Are you sure?',
        text: 'Do you want to save this matched data?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (result.isConfirmed) {

        const cashbooks = JSON.parse(selectedItemsData);
        const statements = JSON.parse(selectedItemsDatas);

        const cashbookIds = cashbooks.map(item => item.id);
        const statementIds = statements.map(item => item.id);
        const cashbookAmounts = cashbooks.map(item => item.amount);
        const statementAmounts = statements.map(item => item.amount);
        // console.log(cashbookIds, statementIds, cashbookAmounts, statementAmounts);
        // return;
        const response = await axios.post(`${BASE_URL}/account/manual-match`,
          {
            statements: statementIds,  // Use the appropriate data from the parsedItems
            statements_amount: statementAmounts,
            cashbooks_amount: cashbookAmounts,
            cashbooks: cashbookIds,
          },
          { headers });

        await AsyncStorage.removeItem('cashbook');
        await AsyncStorage.removeItem('statement');
        fetchTransaction(selectedBank);

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });

      }
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
          toast.error(errorMessage);
        }
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSave2 = async () => {
    setLoading(true);

    try {

      const selectedItemsData = await AsyncStorage.getItem('unMatchedCashbook');
      const selectedItemsDatas = await AsyncStorage.getItem('unMatchedStatement');

      if (!selectedItemsData || !selectedItemsDatas) {

        Swal.fire({
          icon: 'warning',
          title: 'No Saved Data',
          text: 'There is no data saved. Please select items before proceeding.',
        });
        return;
      }


      const result = await Swal.fire({
        icon: 'question',
        title: 'Are you sure?',
        text: 'Do you want to unmatch this data?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (result.isConfirmed) {

        const cashbooks = JSON.parse(selectedItemsData);
        const statements = JSON.parse(selectedItemsDatas);

        const cashbookIds = cashbooks.map(item => item.match_id);
        const statementIds = statements.map(item => item.match_id);
        const combinedIds = [...cashbookIds, ...statementIds];

        console.log(combinedIds, cashbookIds, statementIds);

        // const cashbookAmounts = cashbooks.map(item => item.amount);
        // const statementAmounts = statements.map(item => item.amount);
        // console.log(cashbookIds, statementIds, cashbookAmounts, statementAmounts);
        // return;
        const response = await axios.post(`${BASE_URL}/account/manual-unmatch`,
          {
            match_id: combinedIds,
            // statements: statementIds,  // Use the appropriate data from the parsedItems
            // statements_amount: statementAmounts,
            // cashbooks_amount: cashbookAmounts,
            // cashbooks: cashbookIds,
          },
          { headers });

        await AsyncStorage.removeItem('unMatchedCashbook');
        await AsyncStorage.removeItem('unMatchedStatement');
        fetchTransaction(selectedBank);
        setMatchedDisplay(false);
        setUnmatch(false);

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });

      }
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
          toast.error(errorMessage);
        }
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAction = async () => {
    if (unmatch) {
      await handleSave2(); // Call handleSave2 when unmatch is true
    } else {
      await handleSave(); // Call handleSave when unmatch is false
    }
  };

  const handleCheckboxChange = (index) => {
    const updatedSelectedItems = [...selectedItems];
    const selectedItem = currentPageData[index];

    // Determine the amount based on crdr value
    const amount = selectedItem.crdr === "1"
      ? parseFloat(selectedItem.amount) // Use positive value if crdr is "1"
      : -parseFloat(selectedItem.amount); // Use negative value if crdr is "2"

    if (updatedSelectedItems.includes(index)) {
      // Item is already selected, so remove it and adjust the matched amount
      updatedSelectedItems.splice(updatedSelectedItems.indexOf(index), 1);
      setMatchedAmount((prevAmount) => prevAmount - amount);
    } else {
      // Item is not selected, so add it and adjust the matched amount
      updatedSelectedItems.push(index);
      setMatchedAmount((prevAmount) => prevAmount + amount);
    }

    setSelectedItems(updatedSelectedItems);

    // If all checkboxes are selected manually, update "Select All"
    if (updatedSelectedItems.length === currentPageData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };


  const handleCheckboxChanges = (index) => {
    const updatedSelectedItemss = [...selectedItemss];
    const selectedItem = currentPageDatas[index];

    // Determine the value of the amount based on crdr value
    const amount = selectedItem.crdr === "2"
      ? parseFloat(selectedItem.amount) // Use positive value if crdr is "2"
      : -parseFloat(selectedItem.amount); // Use negative value if crdr is "1"

    if (updatedSelectedItemss.includes(index)) {
      // Item is already selected, so deselect it
      updatedSelectedItemss.splice(updatedSelectedItemss.indexOf(index), 1);

      setMatchedAmount((prevAmount) => prevAmount - amount); // Subtract the amount
    } else {
      // Item is not selected, so select it
      updatedSelectedItemss.push(index);

      setMatchedAmount((prevAmount) => prevAmount + amount); // Add the amount
    }

    setSelectedItemss(updatedSelectedItemss);

    // Update "Select All" state (checking if all items are selected)
    setSelectAlls(updatedSelectedItemss.length === currentPageDatas.length);
  };



  // Handle "Select All" checkbox change
  const handleSelectAll = () => {
    if (!selectAll) {
      // Select all items on the current page
      const allSelectedIndexes = currentPageData.map((_, index) => index);

      // Calculate the total matchedAmount based on crdr value (credit or debit)
      const totalAmount = currentPageData.reduce((sum, item) => {
        // Calculate the amount based on crdr value
        const amount = item.crdr === "1"
          ? item.amount  // Positive for credit
          : item.crdr === "2"
            ? -item.amount // Negative for debit
            : item.amount; // Default amount (if crdr is neither 1 nor 2)

        return sum + parseFloat(amount); // Add the amount to the sum
      }, 0);

      // Update the state with all selected items and the total amount
      setSelectedItems(allSelectedIndexes);
      setMatchedAmount(totalAmount);
    } else {
      // Deselect all items on the current page
      setSelectedItems([]);
      setMatchedAmount(0); // Reset total matchedAmount when unselecting all
    }

    // Toggle the selectAll state
    setSelectAll(!selectAll);
  };


  const handleSelectAlls = () => {
    setSelectAlls(!selectAlls);

    if (!selectAlls) {
      // If "Select All" is checked, select all items on the current page
      const updatedSelectedItemss = currentPageDatas.map((_, index) => index);
      setSelectedItemss(updatedSelectedItemss);

      // Calculate the total matchedAmount for all selected items
      const totalAmount = currentPageDatas.reduce((total, item, index) => {
        // Calculate the amount based on crdr value (credit or debit)
        const amount = item.crdr === "2"
          ? item.amount  // Positive for credit
          : item.crdr === "1"
            ? -item.amount // Negative for debit
            : item.amount; // Default amount (if crdr is neither 1 nor 2)

        return total + parseFloat(amount); // Add the amount to the total
      }, 0);

      setMatchedAmount(totalAmount); // Update the matchedAmount with the total sum of all selected items
    } else {
      // If "Select All" is unchecked, clear all selections
      setSelectedItemss([]);
      setMatchedAmount(0); // Reset matchedAmount when unselecting all
    }
  };



  useEffect(() => {
    const sum = tableData.reduce((acc, item) => acc + parseFloat(item.crdr === "1" ? item.amount : item.crdr === "2" ? -item.amount : item.amount), 0);
    setTotalCashbookBalance(sum);
  }, [tableData]);

  useEffect(() => {
    const balance = tableDatas.reduce((sum, item) => {
      const value = parseFloat(
        item.crdr === "2" ? item.amount : item.crdr === "1" ? -item.amount : item.amount
        // item.debit === "0.00" ? item.credit : (item.credit === "0.00" ? -item.debit : item.debit)
      );
      return sum + value;
    }, 0);
    setTotalStatementBalance(balance);
  }, [tableDatas]);

  const handleMatch = async () => {
    if (matchedAmount !== 0) return;

    if (selectedItems.length === 0 && selectedItemss.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Select a row to start matching',
      });
      return;
    }

    try {
      const selectedItemsData = currentPageData.filter((_, index) => selectedItems.includes(index));
      const selectedItemsDatas = currentPageDatas.filter((_, index) => selectedItemss.includes(index));

      await AsyncStorage.setItem('cashbook', JSON.stringify(selectedItemsData));
      await AsyncStorage.setItem('statement', JSON.stringify(selectedItemsDatas));
      setUnmatch(false);


      // Modify currentPageData directly but trigger re-render with forceUpdate
      const updatedFilteredData = currentPageData.filter((_, index) => !selectedItems.includes(index));
      const updatedFilteredDatas = currentPageDatas.filter((_, index) => !selectedItemss.includes(index));
      setCurrentPageData(updatedFilteredData);
      setCurrentPageDatas(updatedFilteredDatas);
      console.log(updatedFilteredData, updatedFilteredDatas);

      setSelectedItems([]);
      setSelectedItemss([]);

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Checked items saved successfully!',
      });
    } catch (error) {
      console.error('Error saving checked items to AsyncStorage:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to save checked items. Please try again.',
      });
    }
  };
  const handleUnMatch = async () => {
    // if (matchedAmount !== 0) return;

    if (selectedItems.length === 0 && selectedItemss.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Select a row to start unmatching',
      });
      return;
    }

    try {
      const selectedItemsData = currentPageData.filter((_, index) => selectedItems.includes(index));
      const selectedItemsDatas = currentPageDatas.filter((_, index) => selectedItemss.includes(index));

      await AsyncStorage.setItem('unMatchedCashbook', JSON.stringify(selectedItemsData));
      await AsyncStorage.setItem('unMatchedStatement', JSON.stringify(selectedItemsDatas));
setUnmatch(true);
setMatchedAmount('0.00');

      // Modify currentPageData directly but trigger re-render with forceUpdate
      const updatedFilteredData = currentPageData.filter((_, index) => !selectedItems.includes(index));
      const updatedFilteredDatas = currentPageDatas.filter((_, index) => !selectedItemss.includes(index));

      setCurrentPageData(updatedFilteredData);
      setCurrentPageDatas(updatedFilteredDatas);
      
      console.log(updatedFilteredData, updatedFilteredDatas);

      setSelectedItems([]);
      setSelectedItemss([]);

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Checked items saved successfully!',
      });
    } catch (error) {
      console.error('Error saving checked items to AsyncStorage:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to save checked items. Please try again.',
      });
    }
  };

  const [period, setPeriod] = useState([]);
  const [document, setDocument] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectBank, setSelectBank] = useState('');
  const [periodLoading, setPeriodLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);

  const handlePeriodChange = (selectedOption) => {
    setSelectedPeriod(selectedOption.value);
  };
  const handleBankk = (selectedOption) => {
    setSelectBank(selectedOption.value);
  };


  const fetchPeriod = async () => {
    setPeriodLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/provinces/get-province-months-for-bank-statement`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const results = response.data?.data;
      setPeriod(results);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setPeriodLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchPeriod();
    }
  }, [bearer]);

  const opt12 = period.map((item) => ({
    label: item.description,
    value: item.description,
  }));

  const handlePrint = async () => {
    setCreateLoading(true);
    try {

      const response = await axios.post(
        `${BASE_URL}/account/reconciliation-summary`,
        {
          gl_code: selectBank,
          date: selectedPeriod,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const result = response.data.data;
      setDocument(result);
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Successful',
      //   text: response.data.message,
      // });
      handleClose();
      navigate('/accounting/reconciliation_report', { state: { document: result } });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setCreateLoading(false);
    }
  };


  return (
    <div>
      {
        openNav && (
          <MobileMenu setOpenNav={setOpenNav} openNav={openNav} />)
      }

      <LatestDash setOpenNav={setOpenNav} openNav={openNav} />

      <div className={classes.finishedbodyCont}>



        <div className={classes.mainasdflkj}>
          <div className={classes.leftasdflkj}>
            <div className={classes.iconsStyle}>
              <IoMdCheckmark
                onClick={() => handleMatch()}
                style={{
                  color: disabled || matchedDisplayed ? 'gray' : 'green',
                  cursor: disabled || matchedDisplayed ? 'not-allowed' : 'pointer',
                  opacity: disabled || matchedDisplayed ? 0.5 : 1,
                  pointerEvents: disabled || matchedDisplayed ? 'none' : 'auto',
                }}
                size={25}
              />
              <ImCancelCircle
              onClick={() => handleUnMatch()}
               style={{ 
               color: !matchedDisplayed ? 'gray' : 'red', 
               cursor: !matchedDisplayed ? 'not-allowed' : 'pointer',
               opacity: !matchedDisplayed ? 0.5 : 1,
               pointerEvents: !matchedDisplayed ? 'none' : 'auto',
                }} size={25} />
              <FaRegSave onClick={() => handleSaveAction()} size={25} style={{ cursor: "pointer" }} />
              <GrPowerCycle style={{ color: 'orange', cursor: "pointer" }} size={25} />
              <FaPrint onClick={handleShow} style={{ color: 'green', cursor: "pointer" }} size={25} />
            </div>
            {/* <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 0 10px 0  15px" }}>Bank Reconciliation</h3>
            <p style={{ margin: " 0 10px 20px 15px" }}>Reconcile Bank Transactions</p> */}
          </div>
          {/* {(currentPageData.length > 0 || currentPageDatas.length > 0) && ( */}
          <div className={classes.iconsStyle}>
            <p style={{ fontWeight: 700, color: "black" }}>
              ₦{matchedAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </p>
          </div>
          {/* )} */}
          <div className={classes.leftasdflkj1}>
            <div className="form-group row" style={{}}>
              {/* <label for="example-text-input" className={`col-sm-4 col-form-label font-weight-400 ${classes.mbllbl}`}>Select Bank:</label> */}
              <div className="col-sm-1" >
                <div style={{ width: 250 }}>
                  <Select

                    options={selectOptions1}
                    placeholder='Select Bank'
                    onChange={handleBankChange}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (provided) => ({
                        ...provided,
                        maxHeight: '300px',
                        // maxWidth: '400px',
                        overflowY: 'auto',
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.leftasdflkj1}>
            <div className="form-group row" style={{}}>
              {/* <label for="example-text-input" className={`col-sm-4 col-form-label font-weight-400 ${classes.mbllbl}`}>Select Bank:</label> */}
              <div className="col-sm-1" >
                <div style={{ width: 250 }}>
                  <Select
                    options={[
                      { value: '', label: 'Select a category' },
                      { value: 1, label: 'All Matched Items' },
                      { value: 2, label: 'Automatch' },
                      { value: 3, label: 'Man-Match Items' },
                      { value: 4, label: 'Reversal' }
                    ]}
                    placeholder="Select Category"
                    onChange={handleMatchChange}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (provided) => ({
                        ...provided,
                        maxHeight: '300px',
                        overflowY: 'auto',
                      }),
                    }}
                  />

                </div>
              </div>
            </div>
          </div>
           <div className={classes.leftasdflkj1}>
            <div className="form-group row" style={{}}>
              {/* <label for="example-text-input" className={`col-sm-4 col-form-label font-weight-400 ${classes.mbllbl}`}>Select Bank:</label> */}
              <div className="col-sm-1" >
                <div style={{ width: 250 }}>
                  <Select

                    options={periodd}
                    placeholder='Select Date'
                    onChange={handlePeriodChangee}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (provided) => ({
                        ...provided,
                        maxHeight: '300px',
                        // maxWidth: '400px',
                        overflowY: 'auto',
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Print Reconciliation Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ marginTop: 20 }}>
              <Form.Label className={classes.formLabelCustom}>Bank:</Form.Label>
              <Select

                options={selectOptions1}
                placeholder='Select Bank'
                onChange={handleBankk}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    // maxWidth: '400px',
                    overflowY: 'auto',
                  }),
                }}
              />
              <Form.Label className={classes.formLabelCustom}>Date:</Form.Label>
              <Select
                className={classes.formControl}
                options={opt12}
                placeholder="Select Date"
                onChange={handlePeriodChange}
                menuPortalTarget={document.body}
                styles={{
                  width: "100%",
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '100px',
                    overflowY: 'auto',

                  }),
                }}
              />
            </Form>
          </Modal.Body>






          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Go back
            </Button>
            <Button variant="success" onClick={handlePrint}>
              {createLoading ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                </>
              ) : (
                "Print Report"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>
            <div className={classes.tab1}>

              {load && (
                <div className="d-flex justify-content-center align-items-center" style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 1000,
                }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}

              {loading && (
                <div className="d-flex justify-content-center align-items-center" style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 1000,
                }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}

              <div style={{ width: "50%" }}>
                <div className={classes.ledgerTop}>
                  <div className={classes.ledgerTop1}>
                    <h4 className={classes.ledgerText}>Ledger (Balance : ₦{parseFloat(totalCashbookBalance).toLocaleString('en-US', {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })})</h4>
                    {/* <p className={classes.ledgerMatched}>111 of 111 matched</p> */}
                  </div>
                  <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} >
                    <div className="text-right modal-effect ">
                      <div id="DataTables_Table_0_filter" className="dataTables_filter">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="mr-2">Search:</div>
                          <input
                            type="search"
                            value={searchTerm}
                            className="form-control form-control-sm"
                            placeholder="Search by details or amount"
                            aria-controls="DataTables_Table_0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.mainTable}>

                  <div className="table-responsive">
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                          <th style={{ width: 0, padding: 0 }}>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th style={{ width: 0, padding: 0 }}></th>
                          <th style={{ width: 0, padding: 0 }}>PostDate</th>
                          <th style={{ width: 0, padding: 0 }}>ValDate</th>
                          <th style={{ width: 0, padding: 0 }}>Details</th>
                          <th style={{ width: 0, padding: 0 }}>Amount</th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: 'nowrap' }}>
                        {currentPageData.length === 0 ? (
                          <tr>
                            <td colSpan="6" className="text-center">No data to be displayed</td>
                          </tr>
                        ) : (
                          currentPageData.map((item, index) => (
                            <tr key={index}>
                              <td style={{ width: 0, padding: 0 }}><input type='checkbox' className={classes.inPutTextBox} checked={selectedItems.includes(index)}
                                onChange={() => handleCheckboxChange(index)} /></td>
                              <td style={{ width: 0, padding: 0 }}><IoChatbubblesSharp /> <FaUpload style={{ fontSize: '10px', }} /><FaDownload style={{ fontSize: '10px' }} /></td>
                              <td style={{ fontSize: 12, width: 0, padding: 0, color: matchedDisplayed === true ? "green" : "black" }}>{formatDate(item.val_date)}</td>
                              <td style={{ fontSize: 12, width: 0, padding: 0, color: matchedDisplayed === true ? "green" : "black" }}>{formatDate(item.transaction_date)}</td>
                              <td style={{ width: 300, fontSize: 12, whiteSpace: "wrap", color: matchedDisplayed === true ? "green" : "black" }}>{item.details}</td>
                              <td style={{ fontSize: 12, textAlign: "right", width: 20, color: matchedDisplayed === true ? "green" : "black" }}>{parseFloat(
                                item.crdr === "1"
                                  ? item.amount
                                  : item.crdr === "2"
                                    ? -item.amount
                                    : item.amount
                              ).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {currentPageData.length > 0 && (
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    {/* Previous Button */}
                    <button
                      style={{
                        border: 'none',
                        backgroundColor: 'gainsboro',
                        borderRadius: 3,
                        height: '2.5rem',
                        width: '100px',
                        fontWeight: 500,
                        fontSize: 14,
                        padding: '0.5rem',
                        fontFamily: 'Nunito',
                        color: '#000',
                        marginRight: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>

                    {/* Page Number Buttons */}
                    {(() => {
                      const maxVisiblePages = 5; // Maximum number of visible pages
                      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
                      let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

                      if (endPage - startPage < maxVisiblePages - 1) {
                        startPage = Math.max(1, endPage - maxVisiblePages + 1);
                      }

                      const pages = [];
                      for (let i = startPage; i <= endPage; i++) {
                        pages.push(
                          <button
                            key={i}
                            className={`btn btn-secondary rounded mx-1 ${currentPage === i ? 'active' : ''
                              }`}
                            style={{
                              width: '30px',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '0',
                              fontWeight: currentPage === i ? 'bold' : 'normal',
                              fontSize: 12,
                            }}
                            onClick={() => setCurrentPage(i)}
                          >
                            {i}
                          </button>
                        );
                      }

                      return pages;
                    })()}

                    {/* Last Page Button */}
                    {totalPages > 5 && currentPage < totalPages - 2 && (
                      <>
                        <span style={{ padding: '0 5px', fontSize: 12 }}>...</span>
                        <button
                          className={`btn btn-primary rounded mx-1 ${currentPage === totalPages ? 'active' : ''
                            }`}
                          style={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0',
                            fontWeight: currentPage === totalPages ? 'bold' : 'normal',
                            fontSize: 12,
                          }}
                          onClick={() => setCurrentPage(totalPages)}
                        >
                          {totalPages}
                        </button>
                      </>
                    )}

                    {/* Next Button */}
                    <button
                      style={{
                        border: 'none',
                        backgroundColor: 'gainsboro',
                        borderRadius: 3,
                        height: '2.5rem',
                        width: '100px',
                        fontWeight: 500,
                        fontSize: 14,
                        padding: '0.5rem',
                        fontFamily: 'Nunito',
                        color: '#000',
                        marginLeft: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>

                )}



              </div>

              <div style={{ width: "50%" }}>
                <div className={classes.ledgerTop}>
                  <div className={classes.ledgerTop1}>
                    <h4 className={classes.ledgerText}>Statement (Balance : ₦{parseFloat(totalStatementBalance).toLocaleString('en-US', {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })})</h4>
                    {/* <p className={classes.ledgerMatched}>111 of 111 matched</p> */}
                  </div>
                  <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                    <div className="text-right modal-effect ">
                      <div id="DataTables_Table_0_filter" className="dataTables_filter">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="mr-2">Search:</div>
                          <input

                            type="search"
                            value={searchTerms}
                            className="form-control form-control-sm"
                            placeholder="Search by details or amount"
                            aria-controls="DataTables_Table_0"
                            onChange={(e) => setSearchTerms(e.target.value)}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.mainTable}>
                  <div className="table-responsive">
                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                          <th style={{ width: 0, padding: 0 }}>
                            <input
                              type="checkbox"
                              checked={selectAlls}
                              onChange={handleSelectAlls}
                            />
                          </th>
                          <th style={{ width: 0, padding: 0 }}></th>
                          <th style={{ width: 0, padding: 0 }}>Post Date</th>
                          <th style={{ width: 0, padding: 0 }}>Val Date</th>
                          <th style={{ width: 0, padding: 0 }}>Details</th>
                          <th style={{ width: 0, padding: 0 }}>Amount</th>
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: 'nowrap' }}>
                        {currentPageDatas.length === 0 ? (
                          <tr>
                            <td colSpan="6" className="text-center">No data to be displayed</td>
                          </tr>
                        ) : (
                          currentPageDatas.map((item, index) => (

                            <tr key={index}>
                              <td style={{ width: 0, padding: 0 }}>

                                <input
                                  type="checkbox"
                                  className={classes.inPutTextBox}
                                  checked={selectedItemss.includes(index)}
                                  onChange={() => handleCheckboxChanges(index)}
                                />
                              </td>
                              <td style={{ width: 0, padding: 0 }}><IoChatbubblesSharp /> <FaUpload style={{ fontSize: '10px' }} /><FaDownload style={{ fontSize: '10px' }} /></td>
                              <td style={{ fontSize: 12, width: 0, padding: 0, color: matchedDisplayed === true ? "green" : "black" }}>{formatDate(item.post_date)}</td>
                              <td style={{ fontSize: 12, width: 0, padding: 0, color: matchedDisplayed === true ? "green" : "black" }}>{formatDate(item.val_date)}</td>
                              <td style={{ width: 300, fontSize: 12, whiteSpace: "wrap", color: matchedDisplayed === true ? "green" : "black" }}>{item.description}</td>
                              <td style={{ fontSize: 12, textAlign: "right", width: 20, color: matchedDisplayed === true ? "green" : "black" }}>
                                {parseFloat(
                                  item.crdr === "2"
                                    ? item.amount
                                    : item.crdr === "1"
                                      ? -item.amount
                                      : item.amount
                                ).toLocaleString('en-US', {
                                  minimumIntegerDigits: 1,
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}
                              </td>

                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {currentPageDatas.length > 0 && (
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    {/* Previous Button */}
                    <button
                      style={{
                        border: 'none',
                        backgroundColor: 'gainsboro',
                        borderRadius: 3,
                        height: '2.5rem',
                        width: '100px',
                        fontWeight: 500,
                        fontSize: 14,
                        padding: '0.5rem',
                        fontFamily: 'Nunito',
                        color: '#000',
                        marginRight: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => setCurrentPages(currentPages - 1)}
                      disabled={currentPages === 1}
                    >
                      Previous
                    </button>

                    {/* Page Number Buttons */}
                    {(() => {
                      const maxVisiblePages = 5; // Maximum number of visible pages
                      let startPage = Math.max(1, currentPages - Math.floor(maxVisiblePages / 2));
                      let endPage = Math.min(totalPages1, startPage + maxVisiblePages - 1);

                      if (endPage - startPage < maxVisiblePages - 1) {
                        startPage = Math.max(1, endPage - maxVisiblePages + 1);
                      }

                      const pages = [];
                      for (let i = startPage; i <= endPage; i++) {
                        pages.push(
                          <button
                            key={i}
                            className={`btn btn-secondary rounded mx-1 ${currentPages === i ? 'active' : ''
                              }`}
                            style={{
                              width: '30px',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '0',
                              fontWeight: currentPages === i ? 'bold' : 'normal',
                              fontSize: 12,
                            }}
                            onClick={() => setCurrentPages(i)}
                          >
                            {i}
                          </button>
                        );
                      }

                      return pages;
                    })()}

                    {/* Last Page Button */}
                    {totalPages1 > 5 && currentPages < totalPages1 - 2 && (
                      <>
                        <span style={{ padding: '0 5px', fontSize: 12 }}>...</span>
                        <button
                          className={`btn btn-primary rounded mx-1 ${currentPages === totalPages1 ? 'active' : ''
                            }`}
                          style={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0',
                            fontWeight: currentPages === totalPages1 ? 'bold' : 'normal',
                            fontSize: 12,
                          }}
                          onClick={() => setCurrentPages(totalPages1)}
                        >
                          {totalPages1}
                        </button>
                      </>
                    )}

                    {/* Next Button */}
                    <button
                      style={{
                        border: 'none',
                        backgroundColor: 'gainsboro',
                        borderRadius: 3,
                        height: '2.5rem',
                        width: '100px',
                        fontWeight: 500,
                        fontSize: 14,
                        padding: '0.5rem',
                        fontFamily: 'Nunito',
                        color: '#000',
                        marginLeft: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() => setCurrentPages(currentPages + 1)}
                      disabled={currentPages === totalPages1}
                    >
                      Next
                    </button>
                  </div>

                )}
              </div>

            </div>
          </div>
        </div>

      </div>






    </div >
  )
}
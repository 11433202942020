import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from './BankReconReport.module.css';
import { BASE_URL } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import MainDashboard from '../../../Main Dashboard/MainDashoard';
import LatestDash from '../../../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LatestAdminDash from '../../../LatestDashboard/LatestAdminDash';
import LatestBudgetDash from '../../../LatestDashboard/LatestBudgetDash';
import { IoChatbubblesSharp } from "react-icons/io5";
import { FaUpload } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { ImCancelCircle } from "react-icons/im";
import { FaRegSave } from "react-icons/fa";
import { GrPowerCycle } from "react-icons/gr";
import LatestAuditDash from '../../../Audit/LatestAuditDash'





function AdminReconciliationn() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [document, setDocument] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableData21, setTableData21] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [userType, setUserType] = useState('');
  const [consolidatedLoading, setConsolidatedLoading] = useState(false);
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [period, setPeriod] = useState([]);
  const [periodLoading, setPeriodLoading] = useState(false);
  const [province, setProvince] = useState('');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value4 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');


      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value4 !== null) {
        setAdmin(value4);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }



    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const handlePeriodChange = (selectedOption) => {
    setSelectedPeriod(selectedOption.value);
  };

  const fetchPeriod = async () => {
    setPeriodLoading(true);
  
    try {
      const response = await axios.get(
        `${BASE_URL}/provinces/get-province-active-months`,
        {
          params: {
            id: selectedProvince,
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );
  
      const results = response.data?.data;
      setPeriod(results);
  
      console.log(results, "Fetched Periods");
    } catch (error) {
      const errorStatus = error.response?.data?.message || "An error occurred.";
      console.error("Error fetching period:", errorStatus);
  
      Swal.fire({
        icon: 'error',
        title: 'Unauthorized',
        text: errorStatus,
      });
    } finally {
      setPeriodLoading(false);
    }
  };
  

  useEffect(() => {
    if (bearer && selectedProvince) {
      fetchPeriod(selectedProvince);
    }
  }, [bearer && selectedProvince]);

  const opt12 = period.map((item) => ({
    label: item,
    value: item,
  }));


  // console.log(selectedAccount);

  const fetchAccounts = async () => {
    setAccounts([]);
    setSavingsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reconciliation-summary-continent`, {
        params: {
          gl_code: selectedAccount,
          date: selectedPeriod,
          continent_id: selectedContinent || "",
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const result = response.data.data;
setDocument(result);
navigate('/accounting/reconciliation_report', { state: { document: result } });
} catch (error) {
  let errorMessage = 'An error occurred. Please try again.';
  if (error.response && error.response.data && error.response.data.message) {
    if (typeof error.response.data.message === 'string') {
      errorMessage = error.response.data.message;
    } else if (Array.isArray(error.response.data.message)) {
      errorMessage = error.response.data.message.join('; ');
    } else if (typeof error.response.data.message === 'object') {
      errorMessage = JSON.stringify(error.response.data.message);
    }
    Swal.fire({
      icon: 'error',
      title: 'Failed',
      text: JSON.stringify(error.response.data.message),
    });
    console.log(error);
  }
    } finally {
      setSavingsLoading(false);
    }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/bank-accounts`, { headers });
      const results = response.data?.data;

      const opt1 = results.map((item) => ({
        label: `${item.gl_code} - ${item.gl_name}`,
        value: item.id,
      }));

      setTableData21(opt1);
      console.log(response);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData21([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);




  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      // setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchContinents();
    }
  }, [bearer]);



  const fetchSubContinents = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, { headers });

      // console.log(response);
      const subCont = response.data?.data;
      const options1 = subCont.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData3(options1);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  const fetchSubRegion = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, { headers });

      // console.log(response);
      const subCont1 = response.data?.data;
      const options2 = subCont1.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData4(options2);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };
  const fetchProvince = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/provinces`, { headers });

      // console.log(response);
      const subCont15 = response.data?.data.provinces;
      const options25 = subCont15.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData45(options25);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegion(selectedRegion);
    }
  }, [bearer, selectedRegion]);

  useEffect(() => {
    if (bearer) {
      fetchProvince();
    }
  }, [bearer]);

  const handleContinentChange = (selectedOption) => {
    setSelectedContinent(selectedOption.value);
    setSelectedRegion('');
    setSelectedProvince('');
    setSelectedAccount(null);
  };


  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption.value)
  }

  const handleProvinceChange = (selectedOption) => {
    setSelectedProvince(selectedOption.value)
  }


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }



  const handlePrintReport = () => {

    const features = "width=800,height=600,resizable=yes,scrollbars=yes,status=yes";


    const newWindow = window.open("", "Print Report", features);

    if (newWindow) {

      const url = `/accounting/print_cashbook_new`;
      const state = { accounts, inputss };

      newWindow.opener = window;
      newWindow.location = url;

      newWindow.onload = () => {
        newWindow.history.pushState(state, "", url);
      };
    } else {
      alert("Failed to open the new window. Please disable pop-up blockers and try again.");
    }
  };

  const [currentPageDatas, setCurrentPageDatas] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [searchTerms, setSearchTerms] = useState("");
  const [tableDatas, setTableDatas] = useState([]);
  const [currentPages, setCurrentPages] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAlls, setSelectAlls] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemss, setSelectedItemss] = useState([]);
  const [totalCashbookBalance, setTotalCashbookBalance] = useState(0);
  const [totalStatementBalance, setTotalStatementBalance] = useState(0);
  const [matchedAmount, setMatchedAmount] = useState(0);
  const [disabled, setDisabled] = useState(true);
  


  useEffect(() => {
    if (matchedAmount === 0) {
      setDisabled(false); 
    } else {
      setDisabled(true); 
    }
  }, [matchedAmount]);


  const ITEMS_PER_PAGE = 100;

  const totalPages = Math.ceil(tableData.length / ITEMS_PER_PAGE);
  const totalPages1 = Math.ceil(tableDatas.length / ITEMS_PER_PAGE);

  const [selectedMatch, setSelectedMatch] = useState(null);

  const handleMatchChange = (selectedOption) => {
    setSelectedMatch(selectedOption.value);

  };

  const handleCheckboxChange = (index) => {
    const updatedSelectedItems = [...selectedItems];
    const selectedItem = currentPageData[index];

    // Determine the amount based on crdr value
    const amount = selectedItem.crdr === "1"
      ? parseFloat(selectedItem.amount) // Use positive value if crdr is "1"
      : -parseFloat(selectedItem.amount); // Use negative value if crdr is "2"

    if (updatedSelectedItems.includes(index)) {
      // Item is already selected, so remove it and adjust the matched amount
      updatedSelectedItems.splice(updatedSelectedItems.indexOf(index), 1);
      setMatchedAmount((prevAmount) => prevAmount - amount);
    } else {
      // Item is not selected, so add it and adjust the matched amount
      updatedSelectedItems.push(index);
      setMatchedAmount((prevAmount) => prevAmount + amount);
    }

    setSelectedItems(updatedSelectedItems);

    // If all checkboxes are selected manually, update "Select All"
    if (updatedSelectedItems.length === currentPageData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };


  const handleCheckboxChanges = (index) => {
    const updatedSelectedItemss = [...selectedItemss];
    const selectedItem = currentPageDatas[index];

    // Determine the value of the amount based on crdr value
    const amount = selectedItem.crdr === "2"
      ? parseFloat(selectedItem.amount) // Use positive value if crdr is "2"
      : -parseFloat(selectedItem.amount); // Use negative value if crdr is "1"

    if (updatedSelectedItemss.includes(index)) {
      // Item is already selected, so deselect it
      updatedSelectedItemss.splice(updatedSelectedItemss.indexOf(index), 1);

      setMatchedAmount((prevAmount) => prevAmount - amount); // Subtract the amount
    } else {
      // Item is not selected, so select it
      updatedSelectedItemss.push(index);

      setMatchedAmount((prevAmount) => prevAmount + amount); // Add the amount
    }

    setSelectedItemss(updatedSelectedItemss);

    // Update "Select All" state (checking if all items are selected)
    setSelectAlls(updatedSelectedItemss.length === currentPageDatas.length);
  };



  // Handle "Select All" checkbox change
  const handleSelectAll = () => {
    if (!selectAll) {
      // Select all items on the current page
      const allSelectedIndexes = currentPageData.map((_, index) => index);

      // Calculate the total matchedAmount based on crdr value (credit or debit)
      const totalAmount = currentPageData.reduce((sum, item) => {
        // Calculate the amount based on crdr value
        const amount = item.crdr === "1"
          ? item.amount  // Positive for credit
          : item.crdr === "2"
            ? -item.amount // Negative for debit
            : item.amount; // Default amount (if crdr is neither 1 nor 2)

        return sum + parseFloat(amount); // Add the amount to the sum
      }, 0);

      // Update the state with all selected items and the total amount
      setSelectedItems(allSelectedIndexes);
      setMatchedAmount(totalAmount);
    } else {
      // Deselect all items on the current page
      setSelectedItems([]);
      setMatchedAmount(0); // Reset total matchedAmount when unselecting all
    }

    // Toggle the selectAll state
    setSelectAll(!selectAll);
  };


  const handleSelectAlls = () => {
    setSelectAlls(!selectAlls);

    if (!selectAlls) {
      // If "Select All" is checked, select all items on the current page
      const updatedSelectedItemss = currentPageDatas.map((_, index) => index);
      setSelectedItemss(updatedSelectedItemss);

      // Calculate the total matchedAmount for all selected items
      const totalAmount = currentPageDatas.reduce((total, item, index) => {
        // Calculate the amount based on crdr value (credit or debit)
        const amount = item.crdr === "2"
          ? item.amount  // Positive for credit
          : item.crdr === "1"
            ? -item.amount // Negative for debit
            : item.amount; // Default amount (if crdr is neither 1 nor 2)

        return total + parseFloat(amount); // Add the amount to the total
      }, 0);

      setMatchedAmount(totalAmount); // Update the matchedAmount with the total sum of all selected items
    } else {
      // If "Select All" is unchecked, clear all selections
      setSelectedItemss([]);
      setMatchedAmount(0); // Reset matchedAmount when unselecting all
    }
  };



  useEffect(() => {
    const sum = tableData.reduce((acc, item) => acc + parseFloat(item.crdr === "1" ? item.amount : item.crdr === "2" ? -item.amount : item.amount), 0);
    setTotalCashbookBalance(sum);
  }, [tableData]);

  useEffect(() => {
    const balance = tableDatas.reduce((sum, item) => {
      const value = parseFloat(
        item.crdr === "2" ? item.amount : item.crdr === "1" ? -item.amount : item.amount
        // item.debit === "0.00" ? item.credit : (item.credit === "0.00" ? -item.debit : item.debit)
      );
      return sum + value;
    }, 0);
    setTotalStatementBalance(balance);
  }, [tableDatas]);

  const handleMatch = async () => {
    if (matchedAmount !== 0) return;

    if (selectedItems.length === 0 && selectedItemss.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Select a row to start matching',
      });
      return;
    }

    try {
      const selectedItemsData = currentPageData.filter((_, index) => selectedItems.includes(index));
      const selectedItemsDatas = currentPageDatas.filter((_, index) => selectedItemss.includes(index));

      await AsyncStorage.setItem('cashbook', JSON.stringify(selectedItemsData));
    await AsyncStorage.setItem('statement', JSON.stringify(selectedItemsDatas));

      
      // Modify currentPageData directly but trigger re-render with forceUpdate
      const updatedFilteredData = currentPageData.filter((_, index) => !selectedItems.includes(index));
      const updatedFilteredDatas = currentPageDatas.filter((_, index) => !selectedItemss.includes(index));

      setCurrentPageData(updatedFilteredData);
      setCurrentPageDatas(updatedFilteredDatas);
      console.log(updatedFilteredData, updatedFilteredDatas);

      setSelectedItems([]);
      setSelectedItemss([]);

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Checked items saved successfully!',
      });
    } catch (error) {
      console.error('Error saving checked items to AsyncStorage:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to save checked items. Please try again.',
      });
    }
  };

  const filterTableData = () => {
    const normalizedSearchTerm = searchTerm.toLowerCase();
    const filteredData = tableData.filter(item =>
      item.details.toLowerCase().includes(normalizedSearchTerm) ||
      item.amount.toString().includes(normalizedSearchTerm)
    );

    // Paginate filtered data
    const paginatedData = filteredData.slice((currentPage - 1) * 100, currentPage * 100);
    setCurrentPageData(paginatedData);
  };

  // Function to filter and paginate data for tableDatas
  const filterTableDatas = () => {
    const normalizedSearchTerms = searchTerms.toLowerCase();
    const filteredDatas = tableDatas.filter(item =>
      item.description.toLowerCase().includes(normalizedSearchTerms) ||
      item.amount.toString().includes(normalizedSearchTerms)
    );

    // Paginate filtered data
    const paginatedDatas = filteredDatas.slice((currentPages - 1) * 100, currentPages * 100);
    setCurrentPageDatas(paginatedDatas);
  };

  useEffect(() => {
    filterTableData();
  }, [searchTerm, currentPage, tableData]);

  useEffect(() => {
    filterTableDatas();
  }, [searchTerms, currentPages, tableDatas]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const daySuffix = getDaySuffix(day);

    return `${day} ${month}, ${year}`;
  }

  function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th'; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  const [selectedBank, setSelectedBank] = useState('');

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption.value);

  };

  const fetchTransaction = async () => {
    setGeneralLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/get_continent_unmatched_reconciliation`,
        {
          params: {
            account_id: selectedBank,
            continent_id: selectedContinent
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      console.log(response);
      const results = response.data?.data?.statements;
      setTableDatas(results);
      const resultss = response.data?.data?.cashbooks;
      setTableData(resultss);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
      setTableDatas([]);
    } finally {
      setGeneralLoading(false);
    }
  };
  const fetchReport = async () => {
    setReportLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}account/reconciliation-summary-continent`,
        {
          params: {
            date: selectedPeriod,
            gl_code: selectedBank,
            continent_id: selectedContinent
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      console.log(response);
      const result = response.data.data;
      setDocument(result);
      navigate('/accounting/reconciliation_report', { state: { document: result } });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setReportLoading(false);
    }
  };
  const fetchReport1 = async () => {
    setReportLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/account/reconciliation-summary-region`,
        {
          params: {
            date: selectedPeriod,
            gl_code: selectedBank,
            continent_id: selectedContinent,
            region_id: selectedRegion,
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      console.log(response);
      const result = response.data.data;
      setDocument(result);
      navigate('/accounting/reconciliation_report', { state: { document: result } });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setReportLoading(false);
    }
  };
  const fetchReport2 = async () => {
    setReportLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/account/reconciliation-summary-province`,
        {
          params: {
            date: selectedPeriod,
            gl_code: selectedBank,
            continent_id: selectedContinent || '',
            region_id: selectedRegion || '',
            province_id: selectedProvince || '',
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      console.log(response);
      const result = response.data.data;
      setDocument(result);
      navigate('/accounting/reconciliation_report', { state: { document: result } });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setReportLoading(false);
    }
  };

  const fetchTransaction1 = async () => {
    setGeneralLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/get_region_unmatched_reconciliation`,
        {
          params: {
            account_id: selectedBank,
            continent_id: selectedContinent || '',
            region_id: selectedRegion || ''
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      console.log(response);
      const results = response.data?.data?.statements;
      setTableDatas(results);
      const resultss = response.data?.data?.cashbooks;
      setTableData(resultss);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
      setTableDatas([]);
    } finally {
      setGeneralLoading(false);
    }
  };

  const fetchTransaction2 = async () => {
    setGeneralLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/get_province_unmatched_reconciliation`,
        {
          params: {
            account_id: selectedBank,
            continent_id: selectedContinent || '',
            region_id: selectedRegion || '',
            province_id: selectedProvince || ''
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      console.log(response);
      const results = response.data?.data?.statements;
      setTableDatas(results);
      const resultss = response.data?.data?.cashbooks;
      setTableData(resultss);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
      setTableDatas([]);
    } finally {
      setGeneralLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedBank) {
      if (selectedContinent && !selectedRegion && !selectedProvince) {
        // If only continent is selected
        fetchTransaction();
      } else if (selectedContinent && selectedRegion && !selectedProvince) {
        // If continent and region are selected
        fetchTransaction1();
      } else if (selectedContinent && selectedRegion && selectedProvince) {
        // If continent, region, and province are selected
        fetchTransaction2();
      }
    }
  }, [bearer, selectedBank, selectedContinent, selectedRegion, selectedProvince]);
  

  const handleProcess = () => {
    if (selectedContinent && !selectedRegion && !selectedProvince) {
      // Only continent is selected
      fetchReport();
    } else if (selectedContinent && selectedRegion && !selectedProvince) {
      // Continent and region are selected
      fetchReport1();
    } else if (selectedContinent && selectedRegion && selectedProvince) {
      // Continent, region, and province are selected
      fetchReport2();
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Missing Parameters',
        text: 'Please select the required fields to proceed.',
      });
    }
  };
  


  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <ToastContainer />
            {/* <MainDashboard /> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div>
                  <LatestAuditDash />

                </div>

                <div className={classes.finishedbodyCont}>
                  {/* <h3 style={{ fontSize: '30px', fontWeight: 700, marginLeft: '20px' }}>AdminReconciliationn</h3>
                  <p style={{ marginLeft: '20px' }}>Process cash book details by selecting the below required field</p> */}

                  {/* <h3 style={{ fontSize: 20, fontWeight: 700, marginLeft: "2rem" }}>Cash Book</h3> */}
                  <div className={classes.topSpace} style={{  }}>
                    <div className={classes.topPadding}>
                      <div className={`${classes.formSecCont} ${classes.awrow}`}>
                        <div className={`card-body ${classes.awrow}`} style={{ border: 'none' }}>


                          <div className={`row ${classes.awrow}`}>
                            <div className={`row ${classes.awrowwesareasdsa}`} style={{ marginTop: 10 }}>

                              <div className="col-md-4" style={{}}>
                                <div className="form-group row">
                                  {/* <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Continent:</label> */}
                                  <div className="col-sm-8">
                                    <Select
                                    placeholder='Select Continent'
                                      onChange={(selectedOption) => handleContinentChange(selectedOption)}
                                      options={selectOptions}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className={`col-md-4  ${classes.newmobile}`}>
                                <div className="form-group row">
                                  {/* <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Region:</label> */}
                                  <div className={`col-sm-8  ${classes.lblrow}`}>
                                    <Select
                                     placeholder='Select Region'
                                      onChange={(selectedOption) => handleRegionChange(selectedOption)}
                                      options={tableData3}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className={`col-md-4  ${classes.newmobile}`}>
                                <div className="form-group row">
                                  {/* <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Province:</label> */}
                                  <div className={`col-sm-8  ${classes.lblrow}`}>
                                    <Select
                                     placeholder='Select Province'
                                      onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                                      options={tableData4}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4  ${classes.newmobile}`}>
                                <div className="form-group row">
                                  {/* <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Province:</label> */}
                                  <div className={`col-sm-8  ${classes.lblrow}`}>
                                  <Select
                                     placeholder='Select Bank'
                                      onChange={(selectedOption) => handleBankChange(selectedOption)}
                                      options={tableData21}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          < div style={{marginTop: 5, }}/>
                          <div className={classes.newDivButttt}>
                            <div className={classes.newDivButtttdatesgen}>
                              <div className="col-md-4">
                                <div className={`form-group row`} style={{ overflow: "visible" }}>
                                  {/* <label
                                    htmlFor="example-text-input"
                                    className="col-sm-8 col-form-label font-weight-400">
                                    Bank:
                                  </label> */}
                                  {/* <div style={{width: 600}}> */}
                                  <div className="col-sm-12" >
                                  <Select
                                      className={classes.formControl}
                                      options={opt12}
                                      placeholder="Select Date"
                                      onChange={handlePeriodChange}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        width: "100%",
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '100px',
                                          overflowY: 'auto',

                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* </div> */}
                              </div>
                              <div className={`col-md-4 ${classes.lbdsfsdsdslrow}`}>
                                <div className={`form-group row`} style={{ overflow: "visible" }}>
                                  {/* <label
                                    htmlFor="example-text-input"
                                    className="col-sm-8 col-form-label font-weight-400">
                                    Select Category:
                                  </label> */}
                                  <div className="col-sm-12">
                                  <OverlayTrigger
                                placement="top"
                                className={classes.btndf1}
                                overlay={
                                  <Tooltip id="button-tooltip" className={classes.btndf1}>
                                    Click here to view reconciliation report
                                  </Tooltip>
                                }
                              >
                                <Button
                                  disabled={!selectedPeriod}
                                  style={{ width: 150, height: "30px", padding: "0", borderRadius: 5, backgroundColor: '#2d995f', borderColor: '#2d995f', marginLeft: 10, marginTop: 5 }}
                                  variant="success"
                                  onClick={handleProcess}
                                >
                                  {reportLoading ? (
                                    <>
                                      <Spinner size="sm" />
                                    </>
                                  ) : (
                                    "View Report"
                                  )}
                                </Button>
                              </OverlayTrigger>

                                  </div>
                                </div>
                              </div>

                            </div>

                            


                          </div>

                        </div>
                      </div>


                    </div>















                    <div style={{ backgroundColor: 'white',  marginTop: 30 }}>
                      {/* <!--Content Header (Page header)--> */}
                      <div className="content-header row align-items-center m-0">


                        <div className="col-sm-8 header-title p-0">
                          <div className="media">
                            {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                              {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                              {/* <small>Create and view your Savings Accounts...</small> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.mainform}>

<div className={classes.loandgrantcards}>
  <div className={classes.tab1}>

    {generalLoading && (
      <div className="d-flex justify-content-center align-items-center" style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1000,
      }}>
        <Spinner animation="border" variant="primary" />
      </div>
    )}
    
   
    <div style={{ width: "50%" }}>
      <div className={classes.ledgerTop}>
        <div className={classes.ledgerTop1}>
          <h4 className={classes.ledgerText}>Ledger (Balance : ₦{parseFloat(totalCashbookBalance).toLocaleString('en-US', {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })})</h4>
          <p className={classes.ledgerMatched}>111 of 111 matched</p>
        </div>
        <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} >
          <div className="text-right modal-effect ">
            <div id="DataTables_Table_0_filter" className="dataTables_filter">
              <div className="d-flex justify-content-start align-items-center">
                <div className="mr-2">Search:</div>
                <input
                  type="search"
                  value={searchTerm}
                  className="form-control form-control-sm"
                  placeholder="Search by details or amount"
                  aria-controls="DataTables_Table_0"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className={classes.mainTable}>

        <div className="table-responsive">
          <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

            <thead style={{ whiteSpace: 'nowrap' }}>
              <tr>
                <th style={{ width: 0, padding: 0 }}>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th style={{ width: 0, padding: 0 }}></th>
                <th style={{ width: 0, padding: 0 }}>PostDate</th>
                <th style={{ width: 0, padding: 0 }}>ValDate</th>
                <th style={{ width: 0, padding: 0 }}>Details</th>
                <th style={{ width: 0, padding: 0 }}>Amount</th>
              </tr>
            </thead>
            <tbody style={{ whiteSpace: 'nowrap' }}>
              {currentPageData.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center">No data to be displayed</td>
                </tr>
              ) : (
                currentPageData.map((item, index) => (
                  <tr key={index}>
                    <td style={{ width: 0, padding: 0 }}><input type='checkbox' className={classes.inPutTextBox} checked={selectedItems.includes(index)}
                      onChange={() => handleCheckboxChange(index)} /></td>
                    <td style={{ width: 0, padding: 0 }}><IoChatbubblesSharp /> <FaUpload style={{ fontSize: '10px', }} /><FaDownload style={{ fontSize: '10px' }} /></td>
                    <td style={{ fontSize: 12, width: 0, padding: 0 }}>{formatDate(item.val_date)}</td>
                    <td style={{ fontSize: 12, width: 0, padding: 0 }}>{formatDate(item.transaction_date)}</td>
                    <td style={{ width: 300, fontSize: 12, whiteSpace: "wrap" }}>{item.details}</td>
                    <td style={{ fontSize: 12, textAlign: "right", width: 20 }}>{parseFloat(
                      item.crdr === "1"
                        ? item.amount
                        : item.crdr === "2"
                          ? -item.amount
                          : item.amount
                    ).toLocaleString('en-US', {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      {currentPageData.length > 0 && (
        <div className="d-flex justify-content-center align-items-center mt-3">
          {/* Previous Button */}
          <button
            style={{
              border: 'none',
              backgroundColor: 'gainsboro',
              borderRadius: 3,
              height: '2.5rem',
              width: '100px',
              fontWeight: 500,
              fontSize: 14,
              padding: '0.5rem',
              fontFamily: 'Nunito',
              color: '#000',
              marginRight: 10,
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          {/* Page Number Buttons */}
          {(() => {
            const maxVisiblePages = 5; // Maximum number of visible pages
            let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
            let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

            if (endPage - startPage < maxVisiblePages - 1) {
              startPage = Math.max(1, endPage - maxVisiblePages + 1);
            }

            const pages = [];
            for (let i = startPage; i <= endPage; i++) {
              pages.push(
                <button
                  key={i}
                  className={`btn btn-secondary rounded mx-1 ${currentPage === i ? 'active' : ''
                    }`}
                  style={{
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0',
                    fontWeight: currentPage === i ? 'bold' : 'normal',
                    fontSize: 12,
                  }}
                  onClick={() => setCurrentPage(i)}
                >
                  {i}
                </button>
              );
            }

            return pages;
          })()}

          {/* Last Page Button */}
          {totalPages > 5 && currentPage < totalPages - 2 && (
            <>
              <span style={{ padding: '0 5px', fontSize: 12 }}>...</span>
              <button
                className={`btn btn-primary rounded mx-1 ${currentPage === totalPages ? 'active' : ''
                  }`}
                style={{
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0',
                  fontWeight: currentPage === totalPages ? 'bold' : 'normal',
                  fontSize: 12,
                }}
                onClick={() => setCurrentPage(totalPages)}
              >
                {totalPages}
              </button>
            </>
          )}

          {/* Next Button */}
          <button
            style={{
              border: 'none',
              backgroundColor: 'gainsboro',
              borderRadius: 3,
              height: '2.5rem',
              width: '100px',
              fontWeight: 500,
              fontSize: 14,
              padding: '0.5rem',
              fontFamily: 'Nunito',
              color: '#000',
              marginLeft: 10,
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>

      )}



    </div>

    <div style={{ width: "50%" }}>
      <div className={classes.ledgerTop}>
        <div className={classes.ledgerTop1}>
          <h4 className={classes.ledgerText}>Statement (Balance : ₦{parseFloat(totalStatementBalance).toLocaleString('en-US', {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })})</h4>
          <p className={classes.ledgerMatched}>111 of 111 matched</p>
        </div>
        <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
          <div className="text-right modal-effect ">
            <div id="DataTables_Table_0_filter" className="dataTables_filter">
              <div className="d-flex justify-content-start align-items-center">
                <div className="mr-2">Search:</div>
                <input

                  type="search"
                  value={searchTerms}
                  className="form-control form-control-sm"
                  placeholder="Search by details or amount"
                  aria-controls="DataTables_Table_0"
                  onChange={(e) => setSearchTerms(e.target.value)}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className={classes.mainTable}>
        <div className="table-responsive">
          <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
            <thead style={{ whiteSpace: 'nowrap' }}>
              <tr>
                <th style={{ width: 0, padding: 0 }}>
                  <input
                    type="checkbox"
                    checked={selectAlls}
                    onChange={handleSelectAlls}
                  />
                </th>
                <th style={{ width: 0, padding: 0 }}></th>
                <th style={{ width: 0, padding: 0 }}>Post Date</th>
                <th style={{ width: 0, padding: 0 }}>Val Date</th>
                <th style={{ width: 0, padding: 0 }}>Details</th>
                <th style={{ width: 0, padding: 0 }}>Amount</th>
              </tr>
            </thead>
            <tbody style={{ whiteSpace: 'nowrap' }}>
              {currentPageDatas.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center">No data to be displayed</td>
                </tr>
              ) : (
                currentPageDatas.map((item, index) => (

                  <tr key={index}>
                    <td style={{ width: 0, padding: 0 }}>

                      <input
                        type="checkbox"
                        className={classes.inPutTextBox}
                        checked={selectedItemss.includes(index)}
                        onChange={() => handleCheckboxChanges(index)}
                      />
                    </td>
                    <td style={{ width: 0, padding: 0 }}><IoChatbubblesSharp /> <FaUpload style={{ fontSize: '10px' }} /><FaDownload style={{ fontSize: '10px' }} /></td>
                    <td style={{ fontSize: 12, width: 0, padding: 0 }}>{formatDate(item.post_date)}</td>
                    <td style={{ fontSize: 12, width: 0, padding: 0 }}>{formatDate(item.val_date)}</td>
                    <td style={{ width: 300, fontSize: 12, whiteSpace: "wrap" }}>{item.description}</td>
                    <td style={{ fontSize: 12, textAlign: "right", width: 20 }}>
                      {parseFloat(
                        item.crdr === "2"
                          ? item.amount
                          : item.crdr === "1"
                            ? -item.amount
                            : item.amount
                      ).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>

                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      {currentPageDatas.length > 0 && (
        <div className="d-flex justify-content-center align-items-center mt-3">
          {/* Previous Button */}
          <button
            style={{
              border: 'none',
              backgroundColor: 'gainsboro',
              borderRadius: 3,
              height: '2.5rem',
              width: '100px',
              fontWeight: 500,
              fontSize: 14,
              padding: '0.5rem',
              fontFamily: 'Nunito',
              color: '#000',
              marginRight: 10,
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPages(currentPages - 1)}
            disabled={currentPages === 1}
          >
            Previous
          </button>

          {/* Page Number Buttons */}
          {(() => {
            const maxVisiblePages = 5; // Maximum number of visible pages
            let startPage = Math.max(1, currentPages - Math.floor(maxVisiblePages / 2));
            let endPage = Math.min(totalPages1, startPage + maxVisiblePages - 1);

            if (endPage - startPage < maxVisiblePages - 1) {
              startPage = Math.max(1, endPage - maxVisiblePages + 1);
            }

            const pages = [];
            for (let i = startPage; i <= endPage; i++) {
              pages.push(
                <button
                  key={i}
                  className={`btn btn-secondary rounded mx-1 ${currentPages === i ? 'active' : ''
                    }`}
                  style={{
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0',
                    fontWeight: currentPages === i ? 'bold' : 'normal',
                    fontSize: 12,
                  }}
                  onClick={() => setCurrentPages(i)}
                >
                  {i}
                </button>
              );
            }

            return pages;
          })()}

          {/* Last Page Button */}
          {totalPages1 > 5 && currentPages < totalPages1 - 2 && (
            <>
              <span style={{ padding: '0 5px', fontSize: 12 }}>...</span>
              <button
                className={`btn btn-primary rounded mx-1 ${currentPages === totalPages1 ? 'active' : ''
                  }`}
                style={{
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0',
                  fontWeight: currentPages === totalPages1 ? 'bold' : 'normal',
                  fontSize: 12,
                }}
                onClick={() => setCurrentPages(totalPages1)}
              >
                {totalPages1}
              </button>
            </>
          )}

          {/* Next Button */}
          <button
            style={{
              border: 'none',
              backgroundColor: 'gainsboro',
              borderRadius: 3,
              height: '2.5rem',
              width: '100px',
              fontWeight: 500,
              fontSize: 14,
              padding: '0.5rem',
              fontFamily: 'Nunito',
              color: '#000',
              marginLeft: 10,
              cursor: 'pointer',
            }}
            onClick={() => setCurrentPages(currentPages + 1)}
            disabled={currentPages === totalPages1}
          >
            Next
          </button>
        </div>

      )}
    </div>

  </div>
</div>
</div>
                     
                    </div>
                  </div>
                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
          {/* <!--/.wrapper--> */}

        </div>
      </div>
    </div>

  );
}

export default AdminReconciliationn;
import React, { useEffect, useState } from 'react'
import classes from './Redeem.module.css'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation, useNavigate } from 'react-router-dom';

const Redeem = () => {
  const [user, setUser] = useState("");
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const document = location.state?.document || [];
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState('');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value3 = await AsyncStorage.getItem('companyName');
      const value4 = await AsyncStorage.getItem('companyEmail');
      const value5 = await AsyncStorage.getItem('companyPhone');

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value3 !== null) {
        setCompany(value3);
      }
      if (value4 !== null) {
        setEmail(value4);
      }
      if (value5 !== null) {
        setPhone(value5);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const regionName = document?.name;

  const totalOCIC = document?.OCIC
  ?.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0);

const totalOCIS = document?.OCIS
  ?.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0);

  const totalODIC = document?.ODIC
  ?.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0);

const totalODIS = document?.ODIS
  ?.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0);

// Calculate the combined total
const combinedTotal = (totalOCIC + totalOCIS).toLocaleString(undefined, { 
  minimumFractionDigits: 2, 
  maximumFractionDigits: 2 
});

const combinedTotalODICS = (totalODIC + totalODIS).toLocaleString(undefined, { 
  minimumFractionDigits: 2, 
  maximumFractionDigits: 2 
});

const COB = parseFloat(document?.COB) || 0;


const result = COB + (parseFloat(combinedTotal.replace(/,/g, '')) - (parseFloat(combinedTotalODICS.replace(/,/g, ''))));

// Format the result to two decimal places with commas
const formattedResult = result.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});





  return (
    <div className={classes.redeemTops}>

      <div className={classes.report}>
        <header className={classes.header}>

          <div className={classes.title}>
            <h1 className={classes.titleTop}>THE REDEEMED CHRISTIAN CHURCH OF GOD</h1>
            <h2 className={classes.subTitle}>RECONCILIATION SUMMARY REPORT AS AT {document?.date}</h2>
          </div>
        </header>

        <div className={classes.details}>
          <div className={classes.row}>
            <span className={classes.region} >PROVINCE: {regionName.toUpperCase()}</span>
            {/* <span className={classes.region} >REGION: {regionName.toUpperCase()}</span> */}
            {/* <span className={classes.region}>PROVINCE:</span> */}
            <span className={classes.region}>CURRENCY: NGN</span>
          </div>
          <div className={classes.rowB}>
            <span className={classes.region}>RECONCILIATION OF: {document?.account?.gl_name.toUpperCase()}</span>
          </div>
        </div>

        <table className={classes.tableData}>
          <tr className={classes.openTop}>
            <td className={classes.ledgerBal}>Ledger Opening Balance:</td>
            <td className={classes.td}></td>
            <td className={classes.ledgerAmount}>{document?.COB.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
          </tr>

          <tr>
            <td colspan="2" className={classes.addT}>ADD :</td>
          </tr>
          <tr>
            <td className={classes.ledgerOt}>Outstanding Credits in LEDGER:</td>
            <td className={classes.amount}>{document?.OCIC
              ?.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0)
              .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
          </tr>
          <tr>
            <td className={classes.ledgerOtC}>Outstanding Credits in STATEMENT:</td>
            <td className={classes.amountC}>{document?.OCIS
              ?.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0)
              .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            <td className={classes.totalC}>{combinedTotal}</td>
          </tr>
          <tr>
            <td colspan="2" className={classes.addT}>LESS:</td>
          </tr>
          <tr>
            <td className={classes.ledgerOt}>Outstanding Debits in LEDGER:</td>
            <td className={classes.amount}>{document?.ODIC
              ?.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0)
              .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
          </tr>
          <tr>
            <td className={classes.ledgerOtC}>Outstanding Debits in STATEMENT:</td>
            <td className={classes.amountC}>{document?.ODIS
              ?.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0)
              .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            <td className={classes.totalC}>{combinedTotalODICS}</td>
          </tr>
          <tr>
            <td className={classes.addStatment}>Statement Balance:</td>
            <td className={classes.td}></td>
            <td className={classes.ledgerAmount}>{document?.SOB.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
          </tr>
          <tr>
            <td className={classes.addStatment}>DIFFERENCE:</td>
            <td className={classes.td}></td>
            <td className={classes.ledgerAmount}>{formattedResult}</td>
          </tr>
        </table>

        <div className={classes.footer}>
          <div className={classes.rowFooter}>
            <span className={classes.addStatment}>PREPARED BY:</span>
            <span className={classes.addT}>Date:</span>
            <span></span>
          </div>
          <div className={classes.rowFooter}>
            <span className={classes.addStatment}>CHECKED BY:</span>
            <span className={classes.addT}>Date:</span>
            <span></span>
          </div>
          <div className={classes.rowFooter}>
            <span className={classes.addStatment}>APPROVED BY:</span>
            <span className={classes.addT}>Date:</span>
            <span></span>
          </div>
        </div>
      </div>
      <div className={classes.redeemTops}>

      <div className={`${classes.report} ${classes.pageBreak}`}>


          <div className={classes.detailsB}>
            <div className={classes.row}>
              <span className={classes.regions}>Outstanding Debits in Ledger</span>
            </div>
            <div className={classes.mainTable}>
            <div className="table-responsive">
            <table className="table display table-bordered  table-hover bg-white m-0 card-table">
              <thead>
                <tr style={{ whiteSpace: 'nowrap' }}>
                  <th style={{ width: 0, fontSize: 14 }}>Post Date</th>
                  <th style={{ width: 0, fontSize: 14 }}>Val Date</th>
                  <th style={{ width: 0, fontSize: 14 }}>Details</th>
                  <th style={{ width: 0, fontSize: 14, textAlign: "right" }}>Amount</th>
                  <th style={{ width: 0, fontSize: 14 }}>Remark</th>
                </tr>
              </thead>
              <tbody>
                {document?.ODIC
                  // ?.filter(item => item.crdr === "1")
                  .map((item, index) => (
                    <tr key={index} >
                      <td style={{ fontSize: 14, width: 0, whiteSpace: "nowrap"}}>{item.val_date}</td>
                      <td style={{ fontSize: 14, width: 0, whiteSpace: "nowrap"}}>{item.transaction_date}</td>
                      <td style={{ fontSize: 14, width: 500,}}>{item.details}</td>
                      <td style={{ fontSize: 14, width: 0, textAlign: "right"}}>
                        {parseFloat(item.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </td>
                      <td style={{ fontSize: 14, width: 0,}}>{item.remark}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>
            </div>

          </div>
          <div className={classes.detailsB}>
            <div className={classes.row}>
              <span className={classes.regions}>Outstanding Credits in Ledger</span>
            </div>
            <div className={classes.mainTable}>
            <div className="table-responsive">
            <table className="table display table-bordered  table-hover bg-white m-0 card-table">
              <thead>
                <tr style={{ whiteSpace: 'nowrap' }}>
                  <th style={{ width: 0, fontSize: 14 }}>Post Date</th>
                  <th style={{ width: 0, fontSize: 14 }}>Val Date</th>
                  <th style={{ width: 0, fontSize: 14 }}>Details</th>
                  <th style={{ width: 0, fontSize: 14, textAlign: "right" }}>Amount</th>
                  <th style={{ width: 0, fontSize: 14 }}>Remark</th>
                </tr>
              </thead>
              <tbody style={{ whiteSpace: 'nowrap' }}>
                {document?.OCIC
                  // ?.filter(item => item.crdr === "2")
                  .map((item, index) => (
                    <tr key={index} >
                      <td style={{ fontSize: 14, width: 0, whiteSpace: "nowrap"}}>{item.val_date}</td>
                      <td style={{ fontSize: 14, width: 0, whiteSpace: "nowrap"}}>{item.transaction_date}</td>
                      <td style={{ fontSize: 14, width: 500,}}>{item.details}</td>
                      <td style={{ fontSize: 14, width: 0,  textAlign: "right"}}>{parseFloat(item.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </td>
                      <td style={{ fontSize: 14, width: 0, }}>{item.remark}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>
            </div>
          </div>
          <div className={classes.detailsB}>
            <div className={classes.row}>
              <span className={classes.regions}>Outstanding Debits in Statement</span>
            </div>
            <div className={classes.mainTable}>
            <div className="table-responsive">
            <table className="table display table-bordered  table-hover bg-white m-0 card-table">
              <thead>
                <tr style={{ whiteSpace: 'nowrap' }}>
                  <th style={{ width: 0, fontSize: 14 }}>Post Date</th>
                  <th style={{ width: 0, fontSize: 14 }}>Val Date</th>
                  <th style={{ width: 0, fontSize: 14 }}>Details</th>
                  <th style={{ width: 0, fontSize: 14, textAlign: "right" }}>Amount</th>
                  <th style={{ width: 0, fontSize: 14 }}>Remark</th>
                </tr>
              </thead>
              <tbody style={{ whiteSpace: 'nowrap' }}>
                {document?.ODIS
                  // ?.filter(item => item.crdr === "2")
                  .map((item, index) => (
                    <tr key={index} >
                      <td style={{ fontSize: 14, width: 0, whiteSpace: "nowrap"}}>{item.val_date}</td>
                      <td style={{ fontSize: 14, width: 0, whiteSpace: "nowrap"}}>{item.transaction_date}</td>
                      <td style={{ fontSize: 14, width: 500,}}>{item.details}</td>
                      <td style={{ fontSize: 14, width: 0,  textAlign: "right"}}>
                        {parseFloat(item.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </td>
                      <td style={{ fontSize: 14, width: 0, }}>{item.remark}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>
            </div>
          </div>
          <div className={classes.detailsB}>
            <div className={classes.row}>
              <span className={classes.regions}>Outstanding Credits in Statement</span>
            </div>
            <div className={classes.mainTable}>
            <div className="table-responsive">
            <table className="table display table-bordered  table-hover bg-white m-0 card-table">
              <thead>
                <tr style={{ whiteSpace: 'nowrap' }}>
                  <th style={{ width: 0, fontSize: 14 }}>Post Date</th>
                  <th style={{ width: 0, fontSize: 14 }}>Val Date</th>
                  <th style={{ width: 0, fontSize: 14 }}>Details</th>
                  <th style={{ width: 0, fontSize: 14, textAlign: "right" }}>Amount</th>
                  <th style={{ width: 0, fontSize: 14 }}>Remark</th>
                </tr>
              </thead>
              <tbody style={{ whiteSpace: 'nowrap' }}>
                {document?.OCIS
                  // ?.filter(item => item.crdr === "2")
                  .map((item, index) => (
                    <tr key={index} >
                      <td style={{ fontSize: 14, width: 0, whiteSpace: "nowrap"}}>{item.val_date}</td>
                      <td style={{ fontSize: 14, width: 0, whiteSpace: "nowrap"}}>{item.transaction_date}</td>
                      <td style={{ fontSize: 14, width: 500,}}>{item.details}</td>
                      <td style={{ fontSize: 14, width: 0,  textAlign: "right"}}>
                        {parseFloat(item.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </td>
                      <td style={{ fontSize: 14, width: 0, }}>{item.remark}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Redeem
import React, { useState, useEffect } from 'react';
import classes from './ManageRole.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LatestAdminDash from '../LatestDashboard/LatestAdminDash';
import LatestBudgetDash from '../LatestDashboard/LatestBudgetDash';
import { FaCalendarCheck } from "react-icons/fa";
import { FaCalendarXmark } from "react-icons/fa6";



export default function DisapproveWorkBudget() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  // const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  // const handleShow = () => setShow(true);
  const handleShow = (id) => {
    setSelectedItemId(id);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setDescription(''); // Clear the description on close
  };
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [approveLoading, setApproveLoading] = useState(false);
  const [disapproveLoading, setDisapproveLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contLoading, setContLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subContloading, setSubContLoading] = useState(false);
  const [subRegloading, setSubRegLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedContinent, setSelectedContinent] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedProvince1, setSelectedProvince1] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [selectedRole1, setSelectedRole1] = useState("");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState([]);
  const [continents, setContinents] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [userTypes, setUserTypes] = useState('');
  // const [searchTerm, setSearchTerm] = useState('');



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (admins !== null) {
        setUserTypes(admins);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };


  // Filter data based on search term
  // const filteredSearchedResult = searchedResult
  //   .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
  //   // .sort((a, b) => a.name.localeCompare(b.name));

  // const filteredTableData = tableData
  //   .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
  //   .sort((a, b) => a.name.localeCompare(b.name)
  //   );




  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/provinces/get-disapproved-initiated-working-month?page=${currentPage}`,
        { headers }
      );
      const results = response.data?.data?.data || [];
      const totalEntries = response.data?.data?.total || 0;
  
      setTableData(results);
      setTotalEntries(totalEntries);
      setTotalPages(response.data?.data?.last_page || 1);
    } catch (error) {
      console.error("Fetch Data Error:", error);
      setTableData([]); // Clear table on error
    } finally {
      setRoleLoading(false);
    }
  };
  


  const fetchSearch = async (searchTerm) => {
    setSearchLoading(true);
    try {
      if (searchTerm.trim() === "") {
        await fetchData(); // Reset to original data
        return;
      }
  
      const response = await axios.get(`${BASE_URL}/provinces/search-initiated-working-month`, {
        params: { variable: searchTerm },
        headers
      });
      const searchResults = response.data?.data || [];
      setTableData(searchResults); // Update tableData with search results
    } catch (error) {
      console.error("Search Error:", error);
      setTableData([]); // Clear table on error
    } finally {
      setSearchLoading(false);
    }
  };
  

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/all-usertype`, { headers });
      const typeList = response.data?.data;
      console.log("lists:", typeList);
      setUserType(typeList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchContinents = async () => {
    setContLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });
      const contList = response.data?.data;
      // console.log("continents:", contList);
      setContinents(contList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setContLoading(false);
    }
  };

  const fetchSubContinents = async (selectedContinent) => {
    setSubContLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, { headers });
      // console.log(response);
      const subCont = response.data?.data;
      setTableData4(subCont);
      console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      setTableData4([]);
    } finally {
      setSubContLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);

  const fetchSubRegion = async (selectedRegion) => {
    setSubRegLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, { headers });
      // console.log(response);
      const subReg = response.data?.data;
      setTableData5(subReg);
      console.log(subReg, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      setTableData5([]);
    } finally {
      setSubRegLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegion(selectedRegion);
    }
  }, [bearer, selectedRegion]);



  useEffect(() => {
    if (bearer) {
      fetchData1();
      fetchContinents();
    }
  }, [bearer]);


  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer, currentPage]);

  const createUser = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/users/create-new`,
        {
          name: fullName,
          email: email,
          phone_number: phone,
          type: selectedType,
          continent_id: selectedContinent,
          province_id: selectedProvince,
        },
        { headers }
      );
      console.log(response)
      fetchData();
      handleClose();
      setFullName('');
      setPhone('');
      setEmail('');
      setSelectedType('');
      // return
      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  //filter function
  // const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(currentPage - 1, totalEntries);


  const handleEyeClick = (id) => {
    const foundUser = tableData.find((item) => item.id === id);

    if (foundUser) {
      navigate('/accounting/update_user', { state: { userData: foundUser } });
    }
  };
  const handleEyeClick1 = (id) => {
    const foundUser = searchedResult.find((item) => item.id === id);

    if (foundUser) {
      navigate('/accounting/update_user', { state: { userData: foundUser } });
    }
  };


  useEffect(() => {
  }, [selectedUser]);

  const editUser = async (id) => {
    setUpdateLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/users/update-user`,
        {
          name: fullName1,
          id: selectedId,
          email: email1,
          phone_number: phone1,
          type: selectedRole1,
          continent_id: selectedContinent1,
          province_id: selectedProvince1,
          user_id: selectedUser
        },
        { headers }
      );

      fetchData();
      handleClose1();
      toast.success(response.data.message);
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setUpdateLoading(false);
    }
  };


  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this user.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/users/delete?id=${id}`, { headers });
      fetchData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const handleContinentChange = (event) => {
    setSelectedContinent(event.target.value);
  };
  const handleContinentChange1 = (event) => {
    setSelectedContinent1(event.target.value);
  };
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
  };
  const handleRegionChange1 = (event) => {
    setSelectedRegion1(event.target.value);
  };
  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
  };
  const handleProvinceChange1 = (event) => {
    setSelectedProvince1(event.target.value);
  };

  const handleRoleChange1 = (event) => {
    setSelectedRole1(event.target.value);
  };

  const handleCreateUser = () => {
    navigate('/accounting/create_users');
  }


  const handleApprove = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to approve this`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve it!',
      cancelButtonText: 'No, Cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    // Show a Swal saying "Changing the working month, please wait..." and prevent interaction
    Swal.fire({
      title: 'Approving...',
      text: 'Please wait while your request is being processed.',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      }
    });
    setApproveLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/provinces/approve-working-month`,
        {
          id: id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      // Display success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      fetchData();
      console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setApproveLoading(false);
    }
  };

  const handleDisApprove = async () => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to disapprove this`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve it!',
      cancelButtonText: 'No, Cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    // Show a Swal saying "Changing the working month, please wait..." and prevent interaction
    Swal.fire({
      title: 'Disapproving...',
      text: 'Please wait while your request is being processed.',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: false, // Hide the confirm button
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      }
    });
    setDisapproveLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/provinces/disapprove-working-month`,
        {
          id: selectedItemId,
          description: description
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`,
          },
        }
      );

      // Display success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });

      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      console.log(error);
    } finally {
      setDisapproveLoading(false);
    }
  };

  return (
    <div>
      <LatestBudgetDash />

      <div className={classes.finishedbodyCont} >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <h3>Disaproved Working Month Approvals</h3>
            <p>View disapprove working month requests</p>
          </div>
          <div style={{ zIndex: 1 }}>
            {/* <div className={classes.formIntBtn}>
                <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new user</Tooltip>}
                >
                  <Button variant="success" onClick={handleCreateUser} className={classes.btn2}> Add New User</Button>
                </OverlayTrigger>

              </div> */}
          </div>
        </div>


        <div className={classes.mainform}>
          <div className={classes.loandgrantcards}>

            <div className={classes.loandethead}>
              <div className={classes.formLabel}>
              </div>
              {/* <div className={classes.formIntBtn}>
                <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new user</Tooltip>}
                >
                  <Button variant="success" onClick={handleCreateUser} className={classes.btn2}> Add New User</Button>
                </OverlayTrigger>

              </div> */}
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Reason for Disapproving</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Description</Form.Label>
                    <textarea
                      className="form-control"
                      rows="3"
                      cols="50"
                      placeholder="Enter reason here..."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="success" onClick={handleDisApprove}>
                  {disapproveLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                    </>
                  ) : (
                    "Disapprove"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Edit User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Full Name"
                      // autoFocus
                      value={fullName1}
                      onChange={(e) => setFullName1(e.target.value)}
                    />
                  </Form.Group>
                  <div style={{ marginTop: 10 }} />
                  <Form.Group className="mb-3" >
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      // autoFocus
                      value={email1}
                      onChange={(e) => setEmail1(e.target.value)}
                    />
                  </Form.Group>

                  <div style={{ marginTop: 10 }} />
                  <Form.Group className="mb-3" >
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone Number"
                      // autoFocus
                      value={phone1}
                      onChange={(e) => setPhone1(e.target.value)}
                    />
                  </Form.Group>
                  <div style={{ marginTop: 10 }} />
                  <Form.Group className="mb-3" >
                    <Form.Label>Select Type</Form.Label>
                    <Form.Select
                      className="form-control"
                      as="select"
                      value={selectedRole1}
                      onChange={handleRoleChange1}
                    >
                      <option value="" disabled>Select Type</option>
                      {userType.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  {selectedRole1 === '1' && (
                    <>
                      <div style={{ marginTop: 10 }} />
                      <Form.Group className="mb-3">
                        <Form.Label>Select Continent</Form.Label>
                        <Form.Select
                          className="form-control"
                          as="select"
                          value={selectedContinent1}
                          onChange={handleContinentChange1}
                        >
                          <option value="" disabled>Select Continent</option>
                          {continents.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.description}
                            </option>
                          ))}
                        </Form.Select>
                        <div style={{ marginTop: 10 }} />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Select Region</Form.Label>
                        <Form.Select
                          className="form-control"
                          as="select"
                          value={selectedRegion1}
                          onChange={handleRegionChange1}
                        >
                          <option value="" disabled>Select Region</option>
                          {tableData4.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.description}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <div style={{ marginTop: 10 }} />
                      <Form.Group className="mb-3" >
                        <Form.Label>Select Province</Form.Label>
                        <Form.Select
                          className="form-control"
                          as="select"
                          value={selectedProvince1}
                          onChange={handleProvinceChange1}
                        >
                          <option value="" disabled>Select Province</option>
                          {tableData5.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.description}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </>
                  )}
                </Form>
              </Modal.Body>






              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Go back
                </Button>
                <Button variant="success" onClick={editUser}>
                  {updateLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Updating user, Please wait...</span>
                    </>
                  ) : (
                    "Update User"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>


            <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtns}>
                  <Button variant='success' style={{ backgroundColor: '#2d995f', borderColor: '#2d995f' }} >Copy</Button>
                  <Button variant='success' style={{ backgroundColor: '#2d995f', borderColor: '#2d995f' }}>Excel</Button>
                  <Button variant='success' style={{ backgroundColor: '#2d995f', borderColor: '#2d995f' }}>PDF</Button>
                  <Button variant='success' style={{ backgroundColor: '#2d995f', borderColor: '#2d995f' }}>Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
  type="search"
  value={searchTerm}
  className="form-control form-control-sm"
  placeholder="Search..."
  aria-controls="DataTables_Table_0"
  onChange={(e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.trim() === "") {
      fetchData(); // Reset to default data on clear
    }
  }}
/>
<Button
  variant="success"
  onClick={() => fetchSearch(searchTerm)}
  style={{ backgroundColor: '#2d995f', border: 'none', marginLeft: 10 }}
>
  {searchLoading ? <Spinner size="sm" /> : "Search"}
</Button>

                  </div>


                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
              {roleLoading ? (
                <p>Fetching data...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                    <thead style={{ whiteSpace: 'nowrap' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Province Name</th>
                        <th>Last Date</th>
                        <th>Current Date</th>
                        {/* <th>Date Approved</th> */}
                        <th>Disapproved By</th>
                        <th>Status</th>
                        {/* <th>User Type</th> */}
                        {/* <th>Station</th> */}
                        {/* <th>Actions</th> */}
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: "nowrap" }}>
  {tableData.length > 0 ? (
    tableData.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.province_name}</td>
        <td style={{ width: 50 }}>{item.current_date}</td>
        <td>{item.intending_date}</td>
        {/* <td>{item.approved_date}</td> */}
        <td>{item.approved_by_name || item.disapproved_by_name}</td>
        <td style={{ display: "flex", width: 100, justifyContent: "center", alignItems: "center" }}>
                            {item.status === "1" ? (
                              <div
                                style={{
                                  height: 15,
                                  width: 80,
                                  border: "1px solid #50b848",
                                  borderRadius: 3,
                                  color: "#50b848",
                                  fontSize: 10,
                                  textAlign: "center",
                                  fontWeight: 700
                                }}
                              >
                                APPROVED
                              </div>
                            ) : item.status === "2" ? (
                              <div
                                style={{
                                  height: 15,
                                  width: 80,
                                  border: "1px solid #dc3545",
                                  borderRadius: 3,
                                  color: "#dc3545",
                                  fontSize: 10,
                                  textAlign: "center",
                                  fontWeight: 700
                                }}
                              >
                                DISAPPROVED
                              </div>
                            ) : (
                              <div
                                style={{
                                  height: 15,
                                  width: 80,
                                  border: "1px solid #ffc107",
                                  borderRadius: 3,
                                  color: "#ffc107",
                                  fontSize: 10,
                                  textAlign: "center",
                                  fontWeight: 700
                                }}
                              >
                                PENDING
                              </div>
                            )}
                          </td>
                          {/* <td style={{ padding: 5 }}>
                          {item.status !== '1' && item.status !== '2' && (
                              <>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id="button-tooltip">Click to approve this changed working month</Tooltip>}
                                >
                                  <FaCalendarCheck onClick={() => handleApprove(item.id)} style={{ height: 22, color: "#2d995f", cursor: 'pointer', }} />

                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id="button-tooltip">Click to disapprove this working month</Tooltip>}
                                >
                                  <FaCalendarXmark style={{ height: 22, color: "#dc3545", cursor: 'pointer', marginLeft: 10 }} onClick={() => handleShow(item.id)} />

                                </OverlayTrigger>
                              </>
                            )}
                          </td> */}
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="8" style={{ textAlign: "center" }}>
        No data available
      </td>
    </tr>
  )}
</tbody>

                  </table>
                </div>
              )}
            </div>

            <div className={classes.endded}>
  <p>
    Showing {startIndexx} to {endIndexx} of {totalEntries} entries
  </p>
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
  {/* Previous Button */}
  <button
    disabled={currentPage === 1}
    onClick={handlePrevPage}
    style={{
      border: "none",
      backgroundColor: currentPage === 1 ? "#e0e0e0" : "#f0f0f0", // Lighter gray for better contrast
      cursor: currentPage === 1 ? "not-allowed" : "pointer",
      padding: "8px 16px",
      borderRadius: "4px",
      fontSize: "14px",
      fontWeight: "bold",
      transition: "background-color 0.3s ease", // Smooth hover effect
    }}
  >
    Previous
  </button>

  {/* Page Number Buttons */}
  {Array.from({ length: totalPages }, (_, page) => (
    <button
      key={page + 1}
      onClick={() => setCurrentPage(page + 1)}
      style={{
        backgroundColor: page + 1 === currentPage ? "#2d995f" : "#f0f0f0", // Green for active page
        color: page + 1 === currentPage ? "#fff" : "#333", // White for active, dark for others
        padding: "8px 16px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: page + 1 === currentPage ? "bold" : "normal",
        transition: "background-color 0.3s ease, color 0.3s ease", // Smooth hover effect
      }}
    >
      {page + 1}
    </button>
  ))}

  {/* Next Button */}
  <button
    disabled={currentPage === totalPages}
    onClick={handleNextPage}
    style={{
      border: "none",
      backgroundColor: currentPage === totalPages ? "#e0e0e0" : "#f0f0f0", // Lighter gray when disabled
      cursor: currentPage === totalPages ? "not-allowed" : "pointer",
      padding: "8px 16px",
      borderRadius: "4px",
      fontSize: "14px",
      fontWeight: "bold",
      transition: "background-color 0.3s ease", // Smooth hover effect
    }}
  >
    Next
  </button>
</div>

</div>



          </div>
        </div>

      </div>






    </div >
  )
}
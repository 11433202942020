import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Active from '../../assets/NewDashImg/ActiveIcon.svg';
import Icon1 from '../../assets/NewDashImg/Icon1.svg';
import Icon2 from '../../assets/NewDashImg/Icon2.svg';
import Icon3 from '../../assets/NewDashImg/Icon3.svg';
import Icon4 from '../../assets/NewDashImg/Icon4.svg';
import Icon5 from '../../assets/NewDashImg/Icon5.svg';
import Icon6 from '../../assets/NewDashImg/Icon6.svg';
import classes from "./LatestMenu.module.css"

// import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Button, ButtonGroup, Form, Modal, Spinner } from 'react-bootstrap';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BASE_URL } from '../api/api';
import Select from 'react-select';

export default function LatestMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [createLoading, setCreateLoading] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [bearer, setBearer] = useState('');
  const [admin, setAdmin] = useState('');
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [subCat, setSubcat] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [navOpen, setNavOpen] = useState(false);




  // const toggleMenu = () => {
  //   setIsMenuOpen(!isMenuOpen);
  // };

  const toggleNav = () => setNavOpen(!navOpen);

  const navToDashboard = () => {
    navigate('/accounting')
  }



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const uploadStatement = async () => {
    setCreateLoading(true);

    try {

      const formData = new FormData();
      formData.append('date', selectedDate);
      formData.append('file', selectedImage[0]);

      const response = await axios.post(
        `${BASE_URL}/quotes/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log(response.data?.message, "heeee");
      handleClose();
      // Reset form fields
      setSelectedImage("");
      setSelectedDate("");
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      // toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const fetchSubCat = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const results = response.data?.data;
      setSubcat(results);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const opt = subCat.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  useEffect(() => {
    if (bearer) {
      fetchSubCat();
    }
  }, [bearer]);

  return (
    <div>
      <div className={classes.navSection}>
        {/* <div className={classes.hamburger} onClick={toggleNav}>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
        {/* <div className={classes.navSectionHead}>
                <span>
                    <small>Welcome</small>
                    <h4>Eniola Lawanson</h4>
                </span>
                <span>
                    <h4>Dashboard</h4>
                </span>
            </div> */}

        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Statement of Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ marginTop: 20 }}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  // autoFocus
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
                <div style={{ marginTop: 10 }} />
                <Form.Label className={classes.formLabelCustom}>Bank</Form.Label>
                <Select
                  className={classes.formControl}
                  options={opt}
                  placeholder="Select Bank"
                  onChange={handleAccountChange}
                  menuPortalTarget={document.body}
                  styles={{
                    width: "100%",
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '100px',
                      overflowY: 'auto',

                    }),
                  }}
                />


                <div style={{ marginTop: 10 }} />
                <Form.Label>File</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Choose file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={handleImageChange}

                />






              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Go back
            </Button>
            <Button variant="success" onClick={uploadStatement}>
              {createLoading ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                </>
              ) : (
                "Upload"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <div className={classes.hamburger} onClick={toggleNav}>
          {navOpen ? (
            <span className={classes.close}>
              <i className='bx bx-x'></i>
            </span>
          ) : (
            <span className={classes.open}>
              <i className='bx bx-menu'></i>
            </span>
          )}
        </div>

        <div className={`$classes.navSectionNavCont} ${navOpen ? classes.active : ''}`}>
          <div className={`${classes.navSectionNav} ${navOpen ? classes.show : ''}`}>
            {/* <Dropdown> */}
            {/* <Dropdown.Toggle variant='success' className={classes.Dropdowns} id="dropdown-dashboard"> */}
            <Button onClick={navToDashboard} className={`${classes.Dropdowns}`}>
              <span className={classes.active}>
                {/* <img src={Icon3} alt='icon'/> */}
                Dashboard</span>
            </Button>
            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables" style={{  }}>
                <span >
                  {/* <img src={Icon2} alt='icon'/> */}
                  Setup</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/accounting/users">Manage User</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/admin/tax">Manage User Modules</Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/accounting/loan_account">Manage Loan Account</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* </Dropdown.Toggle> */}
            {/* <Dropdown.Menu className={classes.DropdownMenu}>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu> */}
            {/* </Dropdown> */}
            {/* <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-report1">
                <span>
                  
                  Setup</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={`${classes.DropdownMenu} ${classes.reportDropdownMenu}`}>
                <Dropdown.Item as={Link} to="/admin/users">Manage Users</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/salesrep">Manage Sales Rep</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/company">Manage Company</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/exchange_rate">Manage Currency</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/tax">Manage Tax</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

            {/* <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-receivables">
                <span>
                
                  Products</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu} >
                <Dropdown.Item as={Link} to="/account/create_product">Add Products/Services</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/products">Product/Services List</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/unit_of_measurement">Unit of Measurement</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/product_categories">Product/ Services Category</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-receivables">
                <span>
                  {/* <img src={Icon1} alt='icon'/> */}
                  Receivables</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/accounting/customers">Manage Customers</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/admin/allreceivables">Available Receivables</Dropdown.Item> */}
                {/* <Dropdown.Item as={Link} to="/accounting/manage_quote">Manage Quote</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/sales_order">Sales Order</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/sales_invoice">Sales Invoice</Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/accounting/remitances">Monthly Remitances</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/upload_remitance_excel">Monthly Remitance Upload - Excel</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/loan">Loan Beneficiaries</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/receivables/staff_loan">Loan and Advances</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/customers_receipts">Customers' Receipt</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/personal_ledger">Customers' Personal Ledger</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/schedule_of_receivable">Schedule of Receivables</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/accounting/aged_report">Aged Analysis - Receivables</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-payables">
                <span >
                  {/* <img src={Icon2} alt='icon'/> */}
                  Payables</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                <Dropdown.Item as={Link} to="/accounting/supplier">Manage Suppliers/Beneficiary</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/accounting/purchase_order">Purchases Order</Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/accounting/purchase_invoices">Purchases Invoice</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/suppliers_receipt">Suppliers' Payment</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/suppliers_personal_ledger">Suppliers' Personal Ledger</Dropdown.Item>
                <Dropdown.Item as={Link} to="/account_payables_bulk">Account Payables - Bulk </Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/schedule_of_payable">Schedule of Payables</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/accounting/payable_aged_repot">Age Analysis - Payables</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-inventory">
                <span>
                  {/* <img src={Icon3} alt='icon'/> */}
                  Banking</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                {/* <Dropdown.Item as={Link} to='/accounting/statement_of_accountlist'>Statement of Account</Dropdown.Item> */}
                {/* <Dropdown.Item as={Link} to='/accounting/statement_of_bank_account'>Statement of Account</Dropdown.Item> */}
                <Dropdown.Item as={Link} to='/accounting/statement_opening_balance'>Statement of Opening Balance</Dropdown.Item>
                <Dropdown.Item as={Link} to='/accounting/statement_of_balance'>Upload Statement Opening Balance</Dropdown.Item>
                <Dropdown.Item as={Link} to='/accounting/statement_of_account'>Upload Statement of Account</Dropdown.Item>
                <Dropdown.Item as={Link} to="/payment/expenses">Expenses</Dropdown.Item>
                <Dropdown.Item as={Link} to="/payment/payables">Payables</Dropdown.Item>
                <Dropdown.Item as={Link} to="/payment/bulk_payment">Bulk Expenses Postings</Dropdown.Item>

                <Dropdown.Item as={Link} to="/account_payment_voucher">Payment Voucher</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/accounting/bank_transactions">Bank Transactions</Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/accounting/cash_management">Cash Management</Dropdown.Item>
                <Dropdown.Item as={Link} to="/cash_management/bank_lodgement/pending">Bank Lodgement</Dropdown.Item>
                <Dropdown.Item as={Link} to="/cash_management/lodged_transactions">Lodged Transactions</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/bank_reconciliation">Bank Reconciliation</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/budgets">Budgets</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/payroll">Payroll</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-transactions">
                <span>
                  {/* <img src={Icon4} alt='icon'/> */}
                  General Ledger</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.DropdownMenu}>
                {/* <Dropdown.Item as={Link} to="/accounting/supplier">Create Charts of Account</Dropdown.Item> */}
                {/* <Dropdown.Item as={Link} to="/journals/create_journal_entries">Journal Entries</Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/accounting/view_journal_entries">Journal Entries</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/upload_opening_balance">Upload Opening Balance - Excel</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/upload_opening_balance_bulk">Upload Opening Balance - Bulk</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/upload_journal_entry">Upload Journal Entry</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/charts_of_account/account">Charts of Account</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/accounting/reverse_operations">Reverse Operations</Dropdown.Item> */}
                {/* <Dropdown.Item as={Link} to="/accounting/charts_of_account/account">Account List</Dropdown.Item> */}

              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-report1">
                <span>Reports</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className={`${classes.DropdownMenu} ${classes.reportDropdownMenu}`}>
                {/* Nested dropdown for Financial Report */}
                <Dropdown >

                  <Dropdown.Toggle as='div' className={classes.SubDropdownToggle}>
                    <span style={{ fontSize: 14, paddingLeft: 17 }}>Financial Reports</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end" className={classes.SubDropdownMenu}>
                    <Dropdown.Item as={Link} to="/accounting/cashbook">Cashbook</Dropdown.Item>
                   
                    <Dropdown.Item as={Link} to="/accounting/trial_balance">Trial Balance</Dropdown.Item>
                    {/* <Dropdown.Item as={Link} to="/accounting/general_ledger/postings">General Ledger</Dropdown.Item> */}
                    {/* <Dropdown.Item as={Link} to="/accounting/income_statement">Income Statement</Dropdown.Item> */}
                    <Dropdown.Item as={Link} to="/accounting/income_statement_summary">Income Statement - Summary</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/income_statement_detailed">Income Statement - Detailed</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/activity_report_acct">Account Activity Report</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/aged_analysis_payables">Age Analysis - Payables</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/aged_analysis_receivables">Age Analysis - Receivables</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/financial_position">Statement of Financial Position - Summary</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/statement_of_financial_position_admin">Statement of Financial Position - Detailed</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/accounting/bank_report">Bank Position Summary</Dropdown.Item>
                    {/* <Dropdown.Item as={Link} to="/statement_of_cash_flow">Statement of Cash Flow</Dropdown.Item> */}
                    
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown.Item as={Link} to="/accounting/sales_report">Sales Report</Dropdown.Item>
                <Dropdown.Item as={Link} to="/accounting/purchase_report">Purchases Report</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/accounting/reconciliation_summary_report">Reconciliation Summary Report</Dropdown.Item> */}

                {/* {(admin === "Super Admin" || admin === "Continental Accountant" || admin === "Regional Accountant") && (
  <Dropdown>
    <Dropdown.Toggle as="div" className={classes.SubDropdownToggle}>
      <span style={{ fontSize: 14, paddingLeft: 17 }}>Consolidated Reports</span>
    </Dropdown.Toggle>

    <Dropdown.Menu align="end" className={classes.SubDropdownMenu}>
      {admin === "Super Admin" && (
        <>
          <Dropdown.Item as={Link} to="/accounting/consolidatedreport">Regional Reports</Dropdown.Item>
          <Dropdown.Item as={Link} to="/accounting/consolidatedreport">Continental Reports</Dropdown.Item>
        </>
      )}
      {admin === "Continental Accountant" && (
        <Dropdown.Item as={Link} to="/accounting/consolidatedreport">Continental Reports</Dropdown.Item>
      )}
      {admin === "Regional Accountant" && (
        <Dropdown.Item as={Link} to="/accounting/consolidatedreport">Regional Reports</Dropdown.Item>
      )}
    </Dropdown.Menu>
  </Dropdown>
)} */}

              </Dropdown.Menu>

            </Dropdown>





          </div>
        </div>


      </div>
    </div>
  )
}
import React,{useState} from 'react';
import { Button, Modal, Form, Spinner, ToastContainer } from 'react-bootstrap';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import { InfoFooter } from '../../InfoFooter';
import classes from './Customers.module.css';
// import favicon from '../../Images/faviconn.png';
import MainDashoard from '../Main Dashboard/MainDashoard';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MobileMenu from '../../Component/Mobile/MobileNav';
import LatestAuditDash from '../Audit/LatestAuditDash';

function AcctBudgetUI({
  show,
  user,
  stat,
  show1,
  show56,
  handleShow,
  handleShow56,
  handleShow1,
  handleClose,
  handleClose56,
  statLoading,
  handleClose1,
  createCustomer,
  editCustomer,
  isLoading,
  loading,
  searchTerm,
  setSearchTerm,
  entriesPerPage,
  setEntriesPerPage,
  currentPage,
  setCurrentPage,
  totalEntries,
  provinceID,
  totalPages,
  startIndexx,
  benLoading,
  endIndexx,
  displayedData,
  selectedRow,
  uploadLoading,
  handlePrevPage,
  handleNextPage,
  handleEyeClick,
  handleTrashClick,
  fullName,
  setFullName,
  address,
  setAddress,
  office_address,
  setOfficeAddress,
  phone,
  setPhone,
  email,
  setEmail,
  fullName1,
  setFullName1,
  phone1,
  setPhone1,
  email1,
  setEmail1,
  tableData,
  formatDate,
  handleFileChange,
  uploadExcel,
  fetchSearch,
  setSearchedResult,
  searchedResult,
  searchLoading,
  handleEyeClick1,
  accName,
  bankName,
  accNo,
  setAccName,
  setBankName,
  setAccNo,
  setSelectedMonth,
  selectedMonth,
}) {
  const [openNav,setOpenNav] = useState(false);
  const formattedTotalEntries = totalEntries.toLocaleString();
  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  const years = Array.from({ length: currentYear - startYear + 2 }, (_, i) => startYear + i);

  return (
    <div>
        {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    
      <ToastContainer />
    <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>


    <div className={classes.finishedbodyCont}>
            {/* <div className={classes.formLabel}> */}
            {/* <h3 className={classes.productText1}>Customers</h3> */}
            <h3 className={classes.createPrdLg} style={{fontWeight: 700}}>Budgets</h3>
              <p className={classes.subPrdLg}>View and Upload Budgets</p>
            {/* </div> */}
      <div className={classes.mainform}>

        <div className={classes.loandgrantcards}>
         
          <div className={classes.loandethead1}>

          <div className={classes.laondetheadTopCards}>

          {/* <div className={classes.card}>
            <p>Product Total Value</p>
            <h4>0.00</h4>
          </div> */}

          {/* <div className={classes.card}>
            <p style={{marginLeft: 10}}>Total Number of Customer</p>
            <h4 style={{marginLeft: 10}}>{statLoading ? <Spinner size='sm' /> : stat?.total_number_of_customers}</h4>
          </div> */}

          </div>


            <div className={classes.formIntBtn}>
            {/* <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to add new customer</Tooltip>}
                  >
                    <Button variant='success' className={classes.btn2}  onClick={handleShow} >
                      Add New Customer
                    </Button>
                  </OverlayTrigger> */}

                  <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to upload budget in excel format</Tooltip>}
                  >
                  <Button className={classes.btn1} variant="secondary" onClick={handleShow56}>
                    Upload Budget
                  </Button>
                  </OverlayTrigger>
                 
                  <a 
  style={{ marginLeft: 10 }} 
  href={`https://account-api.patna.ng/api/v1/download-budget-template?province_id=${provinceID}`} 
  download
>
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip id="button-tooltip" className={classes.btndf1}>
        Click here to download excel template for uploading use
      </Tooltip>
    }
  >
    <Button variant="success" className={classes.btn22}>
      Download Template
    </Button>
  </OverlayTrigger>
</a>

                      
            </div>
          </div>
          <Modal show={show56} onHide={handleClose56} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Upload Budget</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Year</Form.Label>
                           <Form.Select
        value={selectedMonth}
        onChange={(e) => setSelectedMonth(e.target.value)}
      >
        <option value="">Select Year</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Form.Select>

                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Upload File</Form.Label>
                            <Form.Control
                              type="file"
                              accept=".xlsx, .xls, .csv"
                              onChange={handleFileChange}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>







                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose56}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={uploadExcel}>
                          {uploadLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                            </>
                          ) : (
                            "Upload"
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={show} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>View Budget</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <div className={classes.mainTable}>
  <div className="table-responsive">
   
      <table
        className="table table-bordered table-striped table-hover bg-white m-0"
        style={{ marginBottom: "10px" }}
      >
        <tbody>
          <tr>
            <th>Account Code</th>
            <td>{selectedRow?.account_code}</td>
          </tr>
          <tr>
            <th>Account Name</th>
            <td>{selectedRow?.account_description}</td>
          </tr>
          {Object.keys(selectedRow?.monthly_budgets || {}).map((key) => {
          const monthOrder = [
            'September', 'October', 'November', 'December', 'January', 'February', 'March', 
            'April', 'May', 'June', 'July', 'August'
          ];
          
          // Extract the month number and year from the key
          const monthNumber = key.split('_')[1];  // Extract the month number from `month_1_2024`
          const year = key.split('_')[2]; // Extract the year

          // Calculate the index for the corresponding month in monthOrder
          const monthName = monthOrder[parseInt(monthNumber) - 1]; // Convert to index (0-based)

          return (
            <tr key={key}>
              <th>{monthName} {year}</th>
              <td>
                {parseFloat(selectedRow?.monthly_budgets[key]).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
  
  </div>
</div>

                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                      
                      </Modal.Footer>
                    </Modal>


          <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
            <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
              <div className={classes.actionsBtns}>
                <Button variant='success'>Copy</Button>
                <Button variant='success'>Excel</Button>
                <Button variant='success'>PDF</Button>
                <Button variant='success'>Column visibility</Button>
              </div>
              <div className={classes.show}>
                <label className="d-flex justify-content-start align-items-center">
                  Show
                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                  //  value={entriesPerPage}
                  //     onChange={(e) => {
                  //     setEntriesPerPage(parseInt(e.target.value));
                  //     setCurrentPage(1);
                  //     }}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  entries
                </label>
              </div>
            </div>
            <div className="text-right modal-effect ">
              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="mr-2">Search:</div>
                  <input
  type="search"
  value={searchTerm}
  className="form-control form-control-sm"
  placeholder=""
  aria-controls="DataTables_Table_0"
  onChange={(e) => setSearchTerm(e.target.value)}
/>
                  {/* <Button variant='success' style={{marginLeft: 10, }} onClick= {() => fetchSearch(searchTerm)}>
                {searchLoading ? (
                    <>
                    <Spinner  size='sm' /> 
                   
  </>
) : (
              "Search"
            )}
                </Button> */}
                </div>
                

              </div>
            </div>
          </div>
          <div className={classes.mainTable}>
          {benLoading ? (
                                <p>Fetching data...</p>
                              ) : (
                                <div className="table-responsive">
                                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                    <thead style={{ whiteSpace: 'nowrap' }}>
                                      <tr>
                                        <th>S/N</th>
                                        <th >Account Code</th>
                                        <th >Account Name</th>
                                        {/* {Object.keys(tableData?.[0]?.monthly_budgets || {}).map((key, index) => {
      const monthOrder = ['September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];
      return <th key={key}>{monthOrder[index]}</th>;
    })} */}
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ whiteSpace: 'nowrap' }}>
                                      {displayedData.length === 0 ? (
                                        <tr>
                                          <td colSpan="7">No data available</td>
                                        </tr>
                                      ) : (
displayedData.map((item, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            {/* <td>{item.employee_no}</td> */}
                                            <td style={{ textAlign: "left", }}>{item.account_code}</td>
                                            <td style={{ textAlign: "left",  }}>{item.account_description}</td>
                                            
                                            <td style={{ textAlign: "left" }}>
                                              <div onClick={() => handleEyeClick(item.account_description)} style={{ padding:'2px 10px'}} className="btn btn-success-soft btn-sm mr-1">
                                                <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                              </div>
                                              <div onClick={() => handleTrashClick(item.id)} style={{ padding:0}} className="btn btn-danger-soft btn-sm">
                                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              )}
          </div>

          <div className={classes.endded}>
                              <p>
                                Showing {`Page ${currentPage} to ${totalPages} pages`} of {formattedTotalEntries} entries
                              </p>
                              <div style={{ display: 'flex' }}>
                                <button
                                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>
                                {[...Array(totalPages)].map((_, page) => {
                                  // Show only 5 pages or less if available
                                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                    return (
                                      <button
                                        key={page + 1}
                                        style={{
                                          marginLeft: '0.4rem',
                                          marginRight: '0.4rem',
                                          fontSize: '14px',
                                          fontFamily: 'nunito',
                                          fontWeight: 400,
                                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                                          backgroundColor: page + 1 === currentPage ? '#2d995f' : 'gainsboro',
                                          height: '2.5rem',
                                          borderRadius: '89px',
                                          padding: '0.5rem',
                                          border: 'none',
                                          width: '40px',
                                          cursor: "pointer"
                                        }}
                                        onClick={() => setCurrentPage(page + 1)}
                                      >
                                        {page + 1}
                                      </button>
                                    );
                                  }
                                  return null;
                                })}
                                <button
                                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                  onClick={handleNextPage}
                                  disabled={currentPage === totalPages}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                            <Modal show={show1} onHide={handleClose1} animation={false}>
                              <Modal.Header closeButton>
                                <Modal.Title>Edit User</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form style={{ marginTop: 20 }}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Full Name"
                                      // autoFocus
                                      value={fullName1}
                                      onChange={(e) => setFullName1(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Email Address"
                                      // autoFocus
                                      value={email1}
                                      onChange={(e) => setEmail1(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Phone Number"
                                      // autoFocus
                                      value={phone1}
                                      onChange={(e) => setPhone1(e.target.value)}
                                    />

                                  </Form.Group>
                                </Form>
                              </Modal.Body>






                              <Modal.Footer>
                                <Button variant="danger" onClick={handleClose1}>
                                  Go back
                                </Button>
                                <Button variant="success" onClick={editCustomer} >                             {loading ? <Spinner id="loader" animation="border" variant="warning" /> : 'Save Changes'}
                                </Button>
                              </Modal.Footer>
                            </Modal>
        </div>
      </div>

    </div>

 


  

  </div >
  );
}

export default AcctBudgetUI;
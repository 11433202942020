import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../../../Manage Cooperatives/Manage Members/ManageMember.module.css'
import { BASE_URL } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';

import MainDashboard from '../../../Main Dashboard/MainDashoard';
import LatestDash from '../../../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LatestAdminDash from '../../../LatestDashboard/LatestAdminDash';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import MobileMenu from '../../../../Component/Mobile/MobileNav';



function SOFPAccount() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [drillLoading, setDrillLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalEntries, setTotalEntries] = useState('');

  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [ressult, setRessult] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [consolidatedLoading, setConsolidatedLoading] = useState(false);
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [provinceID, setProvinceID] = useState('');
  const [openNav,setOpenNav] = useState(false);
  const [provin, setProvin] = useState("");
  const [regio, setRegio] = useState("");
  const [contin, setContin] = useState("");
  const [usert, setUsert] = useState("");



  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);


  };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value4 = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');
      const current = await AsyncStorage.getItem('currentMonth');
      const provin = await AsyncStorage.getItem('provinceID');
      const contin = await AsyncStorage.getItem('continentID');
      const regi = await AsyncStorage.getItem('regionID');
      const usert = await AsyncStorage.getItem('userType');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value4 !== null) {
        setAdmin(value4);
      }
      if (regi !== null) {
        setRegio(regi);
      }
      if (contin !== null) {
        setContin(contin);
      }
      if (provin !== null) {
        setProvin(provin);
      
      }
      if (usert !== null) {
        setUsert(usert);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (current !== null) {
        console.log(current);
        const date = new Date(current);
      
        setCurrentMonth(date);
      
        const startOfDateRange = new Date(1900, 0, 1);
     
        const endOfDateRange = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      
        // Format date function as 'YYYY-MM-DD'
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); 
          const day = String(date.getDate()).padStart(2, '0');     
          return `${year}-${month}-${day}`;
        };
      
        setMinDate(formatDate(startOfDateRange));
        setMaxDate(formatDate(endOfDateRange));
      
        console.log("Date Range:", formatDate(startOfDateRange), "to", formatDate(endOfDateRange));
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const fetchAccounts = async () => {
    setAccounts([]);
    setFetchingLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reports/financial-position`, {
        params: {
          // gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,
          continent_id: usert == '2' ? contin : selectedContinent || "",
          region_id: usert == '3' ? regio : selectedRegion || "",
          province_id: usert == '4' ? provin : selectedProvince || ""

        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      
      const ress = response.data?.data?.records || [];
      const ress1 = response.data?.data || [];
      // setRessult(ress);
      setAccounts(ress);
      setInputss(ress1);

      // const resultssx = response.data?.data?.expenses || [];
      // setInputss(resultssx);
      console.log(response);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(error);

      }
    } finally {
      setFetchingLoading(false);
    }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const opt1 = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData(opt1);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);



  // const filteredData = accounts.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);


  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  // const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);



  const fetchPaidBookings = async () => {
    setLedgerLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/reports/general-ledger`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const genLed = response.data?.data;
      setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      console.log(genLed, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLedgerLoading(false);
    }
  };

 

  const fetchGeneralLedger = async () => {
    setGeneralLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account/fetch-member-ledger-by-account?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultssx = response.data?.data;
      setPaidBooking(resultssx);

      console.log(resultssx, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setGeneralLoading(false);
    }
  };



  const fetchConsolidated = async () => {
    setConsolidatedLoading(true);

    try {

      const response = await axios.get(
        `${BASE_URL}/reports/financial-position`, {
        params: {
          // gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,
          continent_id: usert == '2' ? contin : selectedContinent || "",
          region_id: usert == '3' ? regio : selectedRegion || "",
          province_id: usert == '4' ? provin : selectedProvince || ""

        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const filtData1 = response.data?.data?.records || [];
      const filtData3 = response.data?.data || [];
      // const filtData2 = response.data?.data?.expenses || [];

      if (filtData1.length === 0 || filtData3.length === 0) {
        toast.error('No data found for the selected account.');
      } else {
        // setInputss(filtData1);
        setAccounts(filtData1);
        navigate('/print_statement_of_financial_position_detail_consolidated', { state: { accounts: filtData1, inputss: filtData3, selectedDate, selectedEndDate } });

      }
      console.log(response, "consolidated");
      // const genLed = response.data?.data;
      // setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      // console.log(genLed, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setConsolidatedLoading(false);
    }
  };

  const fetchDrilledReport = async () => {
    setDrillLoading(true);

    try {

      const response = await axios.get(
        `${BASE_URL}/reports/financial-position-drilled-down`, {
        params: {
          // gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,
          continent_id: usert == '2' ? contin : selectedContinent || "",
          region_id: usert == '3' ? regio : selectedRegion || "",
          province_id: usert == '4' ? provin : selectedProvince || ""

        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const filtData1 = response.data?.data?.records || [];
      const filtData3 = response.data?.data || [];
      // const filtData2 = response.data?.data?.expenses || [];

      if (filtData1.length === 0 || filtData3.length === 0) {
        toast.error('No data found for the selected account.');
      } else {
        // setInputss(filtData1);
        setAccounts(filtData1);
        navigate('/print_statement_of_financial_position_detail_drilled_down', { state: { accounts: filtData1, inputss: filtData3, selectedDate, selectedEndDate } });

      }
      console.log(response, "consolidated");
      // const genLed = response.data?.data;
      // setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      // console.log(genLed, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setDrillLoading(false);
    }
  };









  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      // setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchContinents();
    }
  }, [bearer]);



  const fetchSubContinents = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions`, { headers });

      // console.log(response);
      const subCont = response.data?.data?.regions;
      const options1 = subCont.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData3(options1);
      // console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  const fetchSubRegion = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, { headers });

      // console.log(response);
      const subCont1 = response.data?.data;
      const options2 = subCont1.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData4(options2);
      console.log(options2);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };
  const fetchProvince = async () => {
    setLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/provinces`, { headers });

      // console.log(response);
      const subCont15 = response.data?.data.provinces;
      const options25 = subCont15.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      setTableData45(options25);
      // console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData3([]);
      }
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchSubContinents();
    }
  }, [bearer]);

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegion(selectedRegion);
    }
  }, [bearer, selectedRegion]);

  useEffect(() => {
    if (bearer) {
      fetchProvince();
    }
  }, [bearer]);

  const handleContinentChange = (selectedOption) => {
    setSelectedContinent(selectedOption.value);
    setSelectedRegion('');
    setSelectedProvince('');
  };


  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption.value)
  }

  const handleProvinceChange = (selectedOption) => {
    setSelectedProvince(selectedOption.value)
    if (admin === 'Continental Accountant') {
      setProvinceOptions(tableData4);
    }
  }


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  // const totalIncome = accounts.reduce((acc, item) => acc + parseFloat(item.amount), 0);
  // const totalExpenses = inputss.reduce((acc, item) => acc + parseFloat(item.amount), 0);

  const handleExcelExport = async () => {
    // Create a workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data Sheet');

    // Define columns (headers)
    worksheet.columns = [
        { header: 'Description', key: 'description', width: 50 },
        { header: 'Amount', key: 'amount', width: 20, style: { alignment: { horizontal: 'right' } } },
    ];

    // Loop through each account in accounts
    accounts?.forEach((item) => {
        // Add Account Section Header
        worksheet.addRow({ description: item.description.toUpperCase() }).font = { bold: true };

        item.categories?.forEach((category) => {
            // Add Category Section Header
            worksheet.addRow({ description: category.description }).font = { italic: true };

            category.postings?.forEach((posting) => {
                // Add Postings
                worksheet.addRow({
                    description: posting.name,
                    amount: parseFloat(posting.amount).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                });
            });

            // Add Category Total
            worksheet.addRow({
                description: 'Total',
                amount: parseFloat(category.total).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            }).font = { bold: true };

            // Add a Spacer Row
            worksheet.addRow({});
        });

        // Add a Spacer Row between Accounts
        worksheet.addRow({});
    });

    // Set up file saving
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, 'STATEMENT OF FINANCIAL POSITION.xlsx');
};

const totalCombined = [accounts[1], accounts[2]].reduce((sum, item) => {
  return sum + item?.categories.reduce((catSum, category) => catSum + parseFloat(category.total), 0);
}, 0);

const totalcCombined = [accounts[1], accounts[2]].reduce((sum, item) => {
  return sum + item?.categories.reduce((catSum, category) => catSum + parseFloat(category.cTotal), 0);
}, 0);




  return (

    <div>
        {
            openNav && ( 
              <MobileMenu setOpenNav={setOpenNav} openNav={openNav}/>)
        }
    

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <ToastContainer />
            {/* <MainDashboard /> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div>
                  <LatestDash setOpenNav={setOpenNav} openNav={openNav}/>

                </div>

                <div className={classes.finishedbodyCont}>
                  <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  20px" }}>Statement of Financial Position - Detail</h3>
                  <p style={{ margin: " 0 10px 20px 20px" }}>View and Print Statement of Financial Position</p>
                  {/* <h3 style={{ fontSize: 20, fontWeight: 700, marginLeft: "2rem" }}>Income Statement</h3> */}
                  {/* <h3 style={{marginLeft:'2rem'}}>Cash Book</h3> */}
                  <div className={classes.topSpace} style={{ backgroundColor: 'white' }}>
                    <div className={classes.topPadding}>
                      <div className={`${classes.formSecCont} ${classes.awrow}`}>
                        <div className={`card-body ${classes.awrow}`} style={{ border: 'none' }}>


                          <div className={`row ${classes.awrow}`}>
                          <div className={`row ${classes.awrow}`} style={{ marginTop: 10 }}>

                            {/* {admin === "Continental Accountant" && (
                              <> */}

                            {admin === "Continental Accountant" && (
                              <>

                                <div className="col-md-4" style={{}}>
                                  <div className="form-group row">
                                    <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Region:</label>
                                    <div className="col-sm-8">
                                      <Select
                                        onChange={(selectedOption) => handleRegionChange(selectedOption)}
                                        options={tableData3}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (provided) => ({
                                            ...provided,
                                            maxHeight: '300px',
                                            maxWidth: '300px',
                                            overflowY: 'auto',
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>


                                <div className="col-md-4" style={{ marginLeft: '-125px' }}>
                                  <div className="form-group row">
                                    <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Province:</label>
                                    <div className={`col-sm-8  ${classes.lblrow}`}>
                                      <Select
                                        onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                                        options={tableData4}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (provided) => ({
                                            ...provided,
                                            maxHeight: '300px',
                                            maxWidth: '300px',
                                            overflowY: 'auto',
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {admin === "Regional Accountant" && (
                              <>

                                {/* <div className="col-md-4" style={{ }}>
                              <div className="form-group row">
                                <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Region:</label>
                                <div className="col-sm-8">
                                <Select
                                    onChange={(selectedOption) => handleRegionChange(selectedOption)}
                                    options={tableData3}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                      menu: (provided) => ({
                                        ...provided,
                                        maxHeight: '300px',
                                        maxWidth: '300px',
                                        overflowY: 'auto',
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div> */}


                                <div className="col-md-4" style={{}}>
                                  <div className="form-group row">
                                    <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Province:</label>
                                    <div className={`col-sm-8  ${classes.lblrow}`}>
                                      <Select
                                        onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                                        options={tableData45}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (provided) => ({
                                            ...provided,
                                            maxHeight: '300px',
                                            maxWidth: '300px',
                                            overflowY: 'auto',
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            </div>

                          </div>
                          <div className={classes.newDivButttt}>
                            <div className={classes.newDivButtttdates}>
                              <div className="col-md-4">
                                <div className={`form-group row`} style={{ overflow: "visible" }}>
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-sm-8 col-form-label font-weight-400">
                                    Start Date:
                                  </label>
                                  <div className="col-sm-12">
                                    <input
                                      className="form-control"
                                      required=""
                                      type="date"
                                      onChange={handleDateChange}
                                      name="start"
                                      value={selectedDate}
                                      min={minDate}
                                      max={maxDate}                                                     
                                      style={{ width: "102%" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4 ${classes.lbdsfslrowddd}`}>
                                <div className={`form-group row`} style={{ overflow: "visible" }}>
                                  <label
                                    htmlFor="example-text-input"
                                    className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow}`}
                                  >
                                    End Date:
                                  </label>
                                  <div className="col-sm-12">
                                    <input
                                      className="form-control"
                                      required=""
                                      type="date"
                                      onChange={handleDateChange1}
                                      name="end"
                                      value={selectedEndDate}
                                      min={minDate}
                                      max={maxDate}                                                     
                                      style={{ width: "102%" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={classes.btndfdafd1}>
                              <OverlayTrigger
                                placement="top"
                                className={classes.btndf1}
                                overlay={
                                  <Tooltip id="button-tooltip" className={classes.btndf1}>
                                    Click here to process statement of financial position - summary
                                  </Tooltip>
                                }
                              >
                                <Button
                                   disabled={
                                    (admin === "Continental Accountant" && (!selectedEndDate  || !selectedDate || !selectedRegion)) ||
                                    (admin === "Regional Accountant" && (!selectedEndDate  || !selectedDate || !selectedProvince)) ||
                                    (admin === "Provincial Accountant" && (!selectedEndDate  || !selectedDate)) ||
                                    (admin === "Super Admin" && (!selectedEndDate  || !selectedDate))
                                  }
                                  style={{ width: 100, height: "30px", padding: "0", borderRadius: 5 }}
                                  variant="success"
                                  onClick={fetchAccounts}
                                >
                                  {fetchingLoading ? (
                                    <>
                                      <Spinner size="sm" />
                                    </>
                                  ) : (
                                    "Process"
                                  )}
                                </Button>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="top"
                                className={classes.btndf1}
                                overlay={
                                  <Tooltip id="button-tooltip" className={classes.btndf1}>
                                    Click here to process statement of financial position drill down report
                                  </Tooltip>
                                }
                              >
                                <Button
                                  disabled={!selectedEndDate || !selectedDate}
                                  style={{ width: 250, height: "30px", padding: "0", borderRadius: 5 }}
                                  variant="success"
                                  onClick={fetchDrilledReport}
                                >
                                  {drillLoading ? (
                                    <>
                                      <Spinner size="sm" />
                                    </>
                                  ) : (
                                    "Process Drilled Down Report"
                                  )}
                                </Button>
                              </OverlayTrigger>

                              {(admin === "Regional Accountant" || admin === "Continental Accountant") && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="button-tooltip" className={classes.btndf1}>
                                      Click here to process consolidated cash book
                                    </Tooltip>
                                  }
                                >
                                  <div className={`${classes.formIdddsntBtn} ${classes.formIntBtnUnitss}`} > 
                                  <Button
                                    className={classes.btn1}
                                    disabled={
                                      (admin === "Continental Accountant" && (!selectedEndDate  || !selectedDate || selectedRegion)) ||
                                      (admin === "Regional Accountant" && (!selectedEndDate  || !selectedDate || selectedProvince)) ||
                                      (admin === "Provincial Accountant" && (!selectedEndDate  || !selectedDate)) ||
                                      (admin === "Super Admin" && (!selectedEndDate  || !selectedDate))
                                    }
                                    style={{ width: 200, height: "30px", padding: "0", borderRadius: 5 }} // Button height set to 10px
                                    variant="primary"
                                    onClick={fetchConsolidated}
                                  >
                                    {consolidatedLoading ? (
                                      <>
                                        <Spinner size="sm" />
                                      </>
                                    ) : (
                                      "Print Consolidated"
                                    )}
                                  </Button>
                                  </div>
                                </OverlayTrigger>
                              )}
                            </div>

                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      {/* <div style={{ marginTop: 20 }} /> */}



                    </div>















                    <div style={{ backgroundColor: 'white', padding: '0px 20px' }}>
                      {/* <!--Content Header (Page header)--> */}
                      <div className="content-header row align-items-center m-0">
                        {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                        {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    justifyContent: "flex-end",
                    display: "flex",
                    marginLeft: "auto",
                  }}
                  className={classes.actionBtns}
                >
                  <Button variant="success" onClick={handleCreate}>
                    Create New Accounts
                  </Button>
                </div>

              </nav> */}
                        {/* )} */}

                        <div className="col-sm-8 header-title p-0">
                          <div className="media">
                            {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                              {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                              {/* <small>Create and view your Savings Accounts...</small> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--/.Content Header (Page header)--> */}
                      <div className="body-content">
                        <div className="row">

                          <div className="col-lg-12 col-xl-6">
                            <div className="row">

                              <div className="col-md-6 col-lg-6">

                                {/* <!--Feedback--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Balance indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Time on site indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Top Referrals--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Sessions by device--> */}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                  <div className="card">


                  </div>
                </div> */}






                          <div className="col-lg-12">
                            <div>
                              <div>
                                {(inputss.length > 0 || accounts.length > 0) && (
                                  <Button
                                    variant='success'
                                    onClick={() => navigate('/print_statement_of_financial_position_detail', { state: { accounts, selectedEndDate, selectedDate, inputss } })}
                                    style={{ height: 30, width: 150, borderRadius: 0, marginTop: 10, marginLeft: 20, justifyContent: "center", alignItems: "center", display: 'flex' }}
                                  >
                                    Print Report
                                  </Button>
                                )}

                              </div>
                              <div className="card-body">
                                <div className="table-resposive">
                                  <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                                    <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                                      <div className={classes.actionsBtns}>
                                        <button>Copy</button>
                                        <button onClick={handleExcelExport}>Excel</button>
                                        <button>PDF</button>
                                        <button className={classes.diffbtn}>Column visibility</button>
                                      </div>
                                      <div className={classes.show}>
                                        <label className="d-flex justify-content-start align-items-center">
                                          Show
                                          <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                            onChange={(e) => {
                                              setEntriesPerPage(parseInt(e.target.value));
                                              setCurrentPage(1);
                                            }}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                          </select>
                                          entries
                                        </label>
                                      </div>
                                    </div>
                                    <div className="text-right modal-effect ">
                                      <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                        <div className="d-flex justify-content-start align-items-center">
                                          <div className="mr-2">Search:</div>
                                          <input
                                            type="search"
                                            value={searchTerm}
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-controls="DataTables_Table_0"
                                            onChange={(e) => {
                                              setSearchTerm(e.target.value);
                                              // setCurrentPage(1);
                                            }}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                  {fetchingLoading ? (
                                    <p>Fetching accounts...</p>
                                  ) : (
                                    <div className="table-responsive">
                                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                        <thead style={{ whiteSpace: 'nowrap' }}>
                                          <tr>
                                            {/* <th>S/N</th> */}
                                            <th>Description</th>
                                            <th>Current Amount</th>
                                            <th>Cummulative Amount</th>
                                            {/* <th>Type</th> */}
                                          </tr>
                                        </thead>
                                        <tbody style={{ whiteSpace: 'nowrap' }}>
                                          {accounts.map((item, index) => (
                                            <React.Fragment key={index}>
                                              <tr>
                                                <th colSpan={4}>{item.description.toUpperCase()}</th>
                                              </tr>
                                              {item.categories?.map((category, catIndex) => (
                                                <React.Fragment key={catIndex}>
                                                  <tr>
                                                    <th colSpan={4} >{category.description}</th>
                                                  </tr>
                                                  {category.postings.map((posting, postIndex) => (
                                                    <tr key={postIndex}>
                                                      <td>{posting.name}</td>
                                                      <td style={{ textAlign: "right" }}>
                                                        {parseFloat(posting.amount).toLocaleString('en-US', {
                                                          minimumIntegerDigits: 1,
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2
                                                        })}
                                                      </td>
                                                      <td style={{ textAlign: "right" }}>
                                                        {parseFloat(posting.cAmount).toLocaleString('en-US', {
                                                          minimumIntegerDigits: 1,
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2
                                                        })}
                                                      </td>
                                                    </tr>
                                                  ))}
                                                  <tr>
                                                    <td colSpan={1} style={{ fontWeight: 'bold' }}>Total </td>
                                                    <td style={{ textAlign: "right", fontWeight: 'bold' }}>
                                                      {parseFloat(category.total).toLocaleString('en-US', {
                                                        minimumIntegerDigits: 1,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      })}
                                                    </td>
                                                    <td style={{ textAlign: "right", fontWeight: 'bold' }}>
                                                      {parseFloat(category.cTotal).toLocaleString('en-US', {
                                                        minimumIntegerDigits: 1,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      })}
                                                    </td>
                                                  </tr>
                                                  <tr><td colSpan={4}>&nbsp;</td></tr>
                                                </React.Fragment>
                                              ))}
                                            </React.Fragment>
                                          ))}
                                          {accounts?.length > 0 && (
                                          <tr>
                                            <td style={{ fontWeight: 'bold' }} >
                                              TOTAL {accounts[1]?.description.toUpperCase()} & {accounts[2]?.description.toUpperCase()}
                                            </td>
                                            <td style={{ textAlign: "right", fontWeight: 'bold' }}>
                                              {totalCombined.toLocaleString('en-US', {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                              })}
                                            </td>
                                            <td style={{ textAlign: "right", fontWeight: 'bold' }}>
                                              {totalcCombined.toLocaleString('en-US', {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                              })}
                                            </td>
                                          </tr>
)}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                  <div className={classes.endded}>
                                    <p>
                                      Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                                    </p>
                                    <div style={{ display: 'flex' }}>
                                      <button
                                        style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                      >
                                        Previous
                                      </button>
                                      {[...Array(totalPages)].map((_, page) => {
                                        // Show only 5 pages or less if available
                                        if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                          return (
                                            <button
                                              key={page + 1}
                                              style={{
                                                marginLeft: '0.4rem',
                                                marginRight: '0.4rem',
                                                fontSize: '14px',
                                                fontFamily: 'nunito',
                                                fontWeight: 400,
                                                color: page + 1 === currentPage ? '#ffffff' : '#000',
                                                backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                                height: '2.5rem',
                                                borderRadius: '89px',
                                                padding: '0.5rem',
                                                border: 'none',
                                                width: '40px',
                                                cursor: "pointer"
                                              }}
                                              onClick={() => setCurrentPage(page + 1)}
                                            >
                                              {page + 1}
                                            </button>
                                          );
                                        }
                                        return null;
                                      })}
                                      <button
                                        style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!--/.body content--> */}
                    </div>
                  </div>
                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
          {/* <!--/.wrapper--> */}

        </div>
      </div>
    </div>

  );
}

export default SOFPAccount;
import React, { useState, useEffect } from 'react';
import classes from './PrintActivity.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default function PrintActivity() {
  const [bearer, setBearer] = useState('');
  const [company, setCompany] = useState('');
  const [showButtons, setShowButtons] = useState(true); // State to control button visibility
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = addLeadingZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const minutes = addLeadingZero(currentDate.getMinutes());
    const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
    const seconds = addLeadingZero(currentDate.getSeconds());
    const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    const formattedDateTime = `${day}/${month}/${year} `;
    setCurrentDateTime(formattedDateTime);
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('companyName');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');
      const admins = await AsyncStorage.getItem('admin');
      if (value !== null) setBearer(value);
      if (value1 !== null) setCompany(value1);
      if (continentName !== null) setContinent(continentName);
      if (regionName !== null) setRegion(regionName);
      if (provinceName !== null) setProvince(provinceName);
      if (admins !== null) setUserType(admins);
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const { accounts, inputss } = location.state || {};
  const startDate = new Date(accounts.start_date);
  const endDate = new Date(accounts.end_date);

  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return v >= 11 && v <= 13
      ? 'th'
      : suffixes[v % 10] || 'th';
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };

  useEffect(() => {
    if (inputss) {
      const creditTotal = inputss
        .filter((item) => !isNaN(item.nCredit) && item.nCredit !== '')
        .reduce((total, item) => total + parseFloat(item.nCredit), 0);

      const debitTotal = inputss
        .filter((item) => !isNaN(item.nDebit) && item.nDebit !== '')
        .reduce((total, item) => total + parseFloat(item.nDebit), 0);

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [inputss]);

  const groupedData = inputss.reduce((acc, item) => {
    if (!acc[item.gl_code]) {
      acc[item.gl_code] = [];
    }
    acc[item.gl_code].push(item);
    return acc;
  }, {});

  const calculateTotals = (group) => {
    return group[0]?.journals?.reduce(
      (totals, item) => {
        totals.debit += parseFloat(item.debit) || 0;
        totals.credit += parseFloat(item.credit) || 0;
        return totals;
      },
      { debit: 0, credit: 0 }
    );
  };

  const handleExcelExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Activity Report');
  
    // Define columns
    worksheet.columns = [
      { header: 'Account', key: 'gl_name', width: 20 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Month', key: 'month', width: 10 },
      { header: 'Date', key: 'date', width: 20 },
      { header: 'Transaction Description', key: 'description', width: 40 },
      { header: 'Debit', key: 'debit', width: 15 },
      { header: 'Credit', key: 'credit', width: 15 },
    ];
  
    // Flatten the data into a single array
    const rows = [];
  
    Object.keys(groupedData).forEach((gl_code) => {
      const group = groupedData[gl_code];
      const totals = calculateTotals(group); // Calculate total for the group
  
      // Add group-level data (e.g., opening balance)
      rows.push({
        gl_name: group[0]?.gl_name,
        name: 'Beginning Balance',
        month: '',
        date: '',
        description: '',
        debit: group[0]?.opening_balance || '0.00',
        credit: '0.00',
      });
  
      // Add all journal rows
      group[0]?.journals?.forEach((item) => {
        rows.push({
          gl_name: group[0]?.gl_name,
          name: item.name,
          month: new Date(item.transaction_date).getMonth() + 1, // Get the month (1-based)
          date: item.transaction_date,
          description: item.details,
          debit: item.debit || '0.00',
          credit: item.credit || '0.00',
        });
      });
  
      // Add total row for this group
      rows.push({
        gl_name: group[0]?.gl_name,
        name: 'Total',
        month: '',
        date: '',
        description: '',
        debit: totals.debit.toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        credit: totals.credit.toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      });
  
      // Add closing balance row for the group
      rows.push({
        gl_name: group[0]?.gl_name,
        name: 'Closing Balance',
        month: '',
        date: '',
        description: '',
        debit: group[0]?.closing_balance || '0.00',
        credit: '0.00',
      });
    });
  
    // Add rows to worksheet
    rows.forEach((row) => {
      worksheet.addRow(row);
    });
  
    // Write the workbook to a buffer and download it
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Activity_Report.xlsx');
  };
  

  const handlePrint = () => {
    // setShowButtons(false); // Hide the buttons
    window.print();
  };

  return (
    <div className={classes.generalbd}>
      <div className={classes.a4}>
        <div className={classes.header} style={{ marginTop: -30 }}>
          <h1>THE REDEEMED CHRISTIAN CHURCH OF GOD</h1>
          <p style={{ width: '100%', fontSize: 18, textTransform: 'uppercase' }}>{accounts?.name ? accounts?.name : province}</p>
          <h6 style={{ fontWeight: 500 }}>
            ACCOUNT ACTIVITY REPORT BETWEEN {accounts.start_date} AND {accounts.end_date}
          </h6>
        </div>
        <p>
          <span style={{ fontWeight: 700, color: '#000' }}>DATE PRINTED:</span> {currentDateTime}
        </p>

        {showButtons && (
          <div>
            <button className={classes.exlbtn} onClick={handleExcelExport}>
              Excel
            </button>
            <button className={classes.printBtn} onClick={handlePrint}>
              Print
            </button>
          </div>
        )}

        <table className={classes.table}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', width: '30px' }}>Account</th>
              <th style={{ textAlign: 'left', width: '10px' }}>Name</th>
              <th style={{ textAlign: 'left', width: '2px' }}>Month</th>
              <th style={{ textAlign: 'left', width: '70px' }}>Date</th>
              <th style={{ textAlign: 'left', width: '200px' }}>Transaction Description</th>
              <th style={{ textAlign: 'right', width: '100px' }}>Debit</th>
              <th style={{ textAlign: 'right', width: '100px' }}>Credit</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedData).map((gl_code) => {
              const group = groupedData[gl_code];
              const totals = calculateTotals(group);
              return (
                <React.Fragment key={gl_code}>
                  <tr>
                    <td colSpan="1" style={{ fontWeight: 'bold' }}>{group[0]?.gl_name}</td>
                    <td colSpan="5" style={{ fontWeight: 'bold', textAlign: 'right' }}>Beginning Balance</td>
                    <td colSpan="1" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {group[0]?.opening_balance.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                  </tr>
                  {group[0]?.journals?.map((item, index) => (
                    <tr key={index}>
                      <td>{group[0]?.gl_code}</td>
                      <td>{item.name}</td>
                      <td>{item.transaction_date}</td>
                      <td>{item.details}</td>
                      <td style={{ textAlign: 'right' }}>{item.debit || '0.00'}</td>
                      <td style={{ textAlign: 'right' }}>{item.credit || '0.00'}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {totals.debit.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {totals.credit.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold' }}>Closing Balance</td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {group[0]?.closing_balance.toLocaleString('en-US', { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
